import React from 'react';
import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { ProcessChainManager } from '../../utils/processChain';

const ViewerHeader = ({ title, subtitle, processId, sessionId, showRerun = true }) => {
  const handleRerun = async () => {
    try {
      // Clear the cache for this process
      ProcessChainManager.clearCache(processId);
      
      // Rerun the process
      await ProcessChainManager.runProcess(processId, sessionId);
      
      // Refresh the page data
      window.location.reload();
    } catch (error) {
      console.error('Error rerunning process:', error);
    }
  };

  return (
    <Box sx={{ 
      textAlign: 'center', 
      mb: 3,
      borderBottom: '1px solid var(--border-color)',
      pb: 2,
      position: 'relative'
    }}>
      {showRerun && (
        <Box sx={{ 
          position: 'absolute', 
          left: 0, 
          top: 0 
        }}>
          <Tooltip title="Rerun this analysis">
            <IconButton 
              onClick={handleRerun}
              size="small"
              sx={{ 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light'
                }
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Typography variant="h4" sx={{ 
        color: 'var(--color-text-primary)',
        fontWeight: 600,
        fontSize: '28px'
      }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" sx={{ 
          color: 'var(--color-text-secondary)',
          mt: 1
        }}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default ViewerHeader;