import React from 'react';
import { TextField, Box, Button, useTheme, Card, CardContent, Typography } from '@mui/material';
import { FaPlay, FaChartLine, FaExclamationTriangle } from 'react-icons/fa';
import ViewerHeader from './common/ViewerHeader';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Import the same theme configuration
const getCustomTheme = (isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: '#FD9800',
      light: 'rgba(253, 152, 0, 0.12)',
    },
    background: {
      paper: isDarkMode ? '#1C1C1E' : '#FFFFFF',
      default: isDarkMode ? '#000000' : '#F5F5F5',
    },
    text: {
      primary: isDarkMode ? '#FFFFFF' : '#1C1C1E',
      secondary: isDarkMode ? '#8E8E93' : '#6C757D',
    },
    divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
  },
});

const IdeaInputCard = ({ 
  inputText, 
  setInputText, 
  hasUnsavedChanges,
  setHasUnsavedChanges,
  onRun,
  config,
  isDarkTheme
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={getCustomTheme(isDarkTheme)}>
      <Box className="process-view-container" sx={{ maxWidth: '800px', margin: '0 auto' }}>
        {/* Problem Statement Header - Using Red for Fear */}
        <Box sx={{ 
          textAlign: 'center', 
          mb: 4,
          mt: 2
        }}>
          <Typography variant="h4" sx={{ 
            fontWeight: 700,
            color: 'error.main',
            mb: 2
          }}>
            Stop Wasting Shareholders Money
          </Typography>
          <Typography variant="h6" sx={{ 
            color: 'text.secondary',
            mb: 3,
            fontWeight: 500
          }}>
            Don't Launch Blind - Validate First
          </Typography>
          
          {/* Visual Stats and Risk Display - All 3 Cards in Row - All Red/Negative */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-around',
            mb: 4,
            gap: 2,
            flexDirection: { xs: 'column', md: 'row' }  // Stack on mobile, row on desktop
          }}>
            {/* Failure Stats Card 1 - Red */}
            <Box sx={{ 
              textAlign: 'center',
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 1,
              flex: 1
            }}>
              <FaExclamationTriangle size={24} color="#FF0000" />
              <Typography variant="h4" sx={{ color: 'error.main', mt: 1 }}>92%</Typography>
              <Typography variant="body2">Startups Fail Due to Poor Market Analysis</Typography>
            </Box>

            {/* Failure Stats Card 2 - Red */}
            <Box sx={{ 
              textAlign: 'center',
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 1,
              flex: 1
            }}>
              <FaExclamationTriangle size={24} color="#FF0000" />
              <Typography variant="h4" sx={{ color: 'error.main', mt: 1 }}>73%</Typography>
              <Typography variant="body2">Waste Money on Wrong Market Approach</Typography>
            </Box>

            {/* Risk Card - Red */}
            <Box sx={{ 
              textAlign: 'center',
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 1,
              flex: 1,
              borderLeft: 4,
              borderColor: 'error.main'
            }}>
              <Typography variant="h6" sx={{ color: 'error.main', mb: 2 }}>
                Don't Risk Your Time and Money
              </Typography>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'flex-start'
              }}>
                {[
                  'Wasting months building the wrong product',
                  'Burning through savings',
                  'Missing market opportunities',
                  'Losing to competitors'
                ].map((point, index) => (
                  <Box key={index} sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <FaExclamationTriangle size={16} color="#FF0000" />
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                      {point}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Solution Section below becomes even more important as contrast */}
        <Box sx={{ 
          textAlign: 'center',
          mb: 4,
          position: 'relative'
        }}>
          <Typography variant="h5" sx={{ 
            color: '#FD9800',
            fontWeight: 600,
            mb: 2
          }}>
            Validate Your Idea First
          </Typography>
        </Box>

        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          placeholder={config.info.placeholderText}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
            setHasUnsavedChanges(true);
          }}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            }
          }}
        />
        
        {/* Action Button - Orange for Solution */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mt: 3,
          mb: 4,
          position: 'relative'
        }}>
          <Button
            id="start-analysis-button"
            variant="contained"
            size="large"
            startIcon={<FaPlay />}
            onClick={onRun}
            disabled={!inputText.trim()}
            sx={{
              minWidth: '250px',
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              bgcolor: '#FD9800',
              boxShadow: '0 4px 12px rgba(253, 152, 0, 0.4)',
              '&:hover': {
                bgcolor: '#FD9800',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 16px rgba(253, 152, 0, 0.6)',
              },
              transition: 'all 0.3s ease',
              '&.Mui-disabled': {
                bgcolor: 'action.disabledBackground',
              },
              '&.highlight': {
                animation: 'pulse 1.5s infinite'
              }
            }}
          >
            Validate Your Idea Now
          </Button>
          <Typography variant="body2" sx={{ 
            color: 'success.main', 
            mt: 1,
            fontWeight: 500
          }}>
            Join the 87% who succeeded with proper validation
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default IdeaInputCard;