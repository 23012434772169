import React, { useState } from 'react';
import { Card, CardHeader,  CardContent } from '@mui/material';
import { Box } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import './glow_21_market-comp-matrix.css';
import processConfig from '../../config/processIndex.json';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

const MarketMatrixViewer = ({ data }) => {
  const markets = Array.isArray(data?.markets) ? data.markets : [];
  const [toast, setToast] = useState({ show: false, text: '', x: 0, y: 0 });
  // Add visibility state
  const [visibleLayers, setVisibleLayers] = useState({
    tam: true,
    sam: true,
    som: true
  });

  // Handler for checkbox changes
  const handleLayerToggle = (layer) => {
    setVisibleLayers(prev => ({
      ...prev,
      [layer]: !prev[layer]
    }));
  };

  // Add tooltip descriptions (same as glow_10)
  const tooltips = {
    tam: "Total Addressable Market (TAM) represents the total market demand for your product or service category. It's the maximum amount of revenue a business could generate if it captured 100% of the market share.",
    sam: "Serviceable Addressable Market (SAM) is the segment of TAM that your company can realistically target with its specific business model and capabilities. It represents the portion of the market that aligns with your product offering and distribution channels.",
    som: "Serviceable Obtainable Market (SOM) is the portion of SAM that you can realistically capture in the near term. It represents your actual target market share based on your company's resources, competition, and market constraints.",
    cagr: "Compound Annual Growth Rate (CAGR) measures the annual growth rate of an investment over a specified time period."
  };

  const handleMouseMove = (e) => {
    if (toast.show) {
      setToast(prev => ({
        ...prev,
        x: e.clientX + 15,
        y: e.clientY + 15
      }));
    }
  };

  // Calculate maximum values for each layer
  const maxValues = {
    tam: Math.max(...markets.map(market => market.tam.value)),
    sam: Math.max(...markets.map(market => market.sam.value)),
    som: Math.max(...markets.map(market => market.som.value))
  };

  // Function to get current maximum value based on visible layers
  const getCurrentMaxValue = (visibleLayers) => {
    const visibleValues = Object.entries(visibleLayers)
      .filter(([_, isVisible]) => isVisible)
      .map(([key]) => maxValues[key]);
    
    return Math.max(...visibleValues);
  };

  // Update the CAGR values calculation to consider visible layers
  const getVisibleCagrValues = (markets, visibleLayers) => {
    return markets.flatMap(market => [
      visibleLayers.tam ? market.tam.cagr : null,
      visibleLayers.sam ? market.sam.cagr : null,
      visibleLayers.som ? market.som.cagr : null
    ]).filter(value => value !== null);
  };

  // Update CAGR calculations to use visible layers
  const cagrValues = getVisibleCagrValues(markets, visibleLayers);
  const maxCagr = Math.max(...cagrValues);
  const minCagr = Math.min(...cagrValues);
  
  // Function to normalize CAGR value between 0-100%
  const normalizeCAGR = (value) => {
    if (maxCagr === minCagr) return 50; // Default to middle if all values are the same
    return ((value - minCagr) / (maxCagr - minCagr)) * 100;
  };

  // Show empty state if no markets data
  if (!markets.length) {
    return (
      <Card className="market-matrix-viewer">
        <CardContent>
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            No market comparison data available. Please ensure the market analysis has been completed.
          </div>
        </CardContent>
      </Card>
    );
  }

  const MarketCircleWithCagr = ({ market, index, visibleLayers }) => {
    const [activeSection, setActiveSection] = useState(null);
    const maxRadius = 100;
    
    // Define color schemes
    const colorSchemes = {
      primary: {
        som: '#FD9800',
        sam: '#FDB544',
        tam: '#FED38C'
      },
      secondary: {
        som: '#666666',
        sam: '#999999',
        tam: '#CCCCCC'
      }
    };
    
    // Use primary colors for first market (index 0), secondary for others
    const colors = index === 0 ? colorSchemes.primary : colorSchemes.secondary;
    
    // Get current maximum value based on visible layers
    const currentMaxValue = getCurrentMaxValue(visibleLayers);
    
    // Calculate radii relative to the current maximum value
    const tamRadius = visibleLayers.tam ? 
      maxRadius * Math.sqrt(market.tam.value / currentMaxValue) : 0;
    const samRadius = visibleLayers.sam ? 
      maxRadius * Math.sqrt(market.sam.value / currentMaxValue) : 0;
    const somRadius = visibleLayers.som ? 
      maxRadius * Math.sqrt(market.som.value / currentMaxValue) : 0;

    const svgHeight = maxRadius * 2;
    const centerY = svgHeight / 2;
    const baseline = centerY + maxRadius;
    const tamY = baseline - tamRadius;
    const samY = baseline - samRadius;
    const somY = baseline - somRadius;

    // Add formatter for billions/millions
    const formatValue = (value) => {
      if (value >= 1e9) {
        return `$${(value / 1e9).toFixed(1)}B`;
      }
      if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(1)}M`; 
      }
      return `$${value.toFixed(1)}K`;
    };

    // Create circles data array with colors
    const circlesData = [
      { key: 'tam', y: tamY, r: tamRadius, color: colors.tam, value: market.tam.value },
      { key: 'sam', y: samY, r: samRadius, color: colors.sam, value: market.sam.value },
      { key: 'som', y: somY, r: somRadius, color: colors.som, value: market.som.value }
    ];

    return (
      <div className="market-container">
        <div className="bubble-visualization">
          <svg viewBox={`0 0 220 ${svgHeight}`} preserveAspectRatio="xMidYMid meet">
            <AnimatePresence>
              {circlesData
                .filter(({ key }) => visibleLayers[key])
                .map(({ key, y, r, color, value }, index) => (
                  <motion.g key={`motion_${key}_${index}`}>
                    <motion.circle
                      cx="110"
                      cy={y}
                      r={r}
                      fill={color}
                      opacity={0.8}
                    />
                    
                    <motion.line 
                      x1="110"
                      y1={y}
                      x2="160"
                      y2={y}
                      stroke={color}
                      strokeWidth="1"
                      strokeDasharray="2,2"
                    />
                    
                    <motion.text
                      x="165"
                      y={y}
                      fill="var(--color-text)"
                      fontSize="12"
                      dominantBaseline="middle"
                      className="market-value-label"
                    >
                      {formatValue(value)}
                    </motion.text>
                  </motion.g>
                ))}
            </AnimatePresence>
          </svg>
        </div>

        <div className="market-title">{market.name}</div>

        {/* CAGR bars */}
        <div className="cagr-container">
          <AnimatePresence>
            {visibleLayers.tam && (
              <motion.div 
                className="cagr-row"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <span className="cagr-label">TAM</span>
                <div className="cagr-bar-container">
                  <motion.div 
                    className={`cagr-bar ${index === 0 ? 'tam-bar' : 'tam-bar-secondary'}`}
                    style={{ backgroundColor: colors.tam }}
                    initial={{ width: 0 }}
                    animate={{ width: `${normalizeCAGR(market.tam.cagr)}%` }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                <span className="cagr-value">{market.tam.cagr}%</span>
              </motion.div>
            )}
            
            {visibleLayers.sam && (
              <motion.div 
                className="cagr-row"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <span className="cagr-label">SAM</span>
                <div className="cagr-bar-container">
                  <motion.div 
                    className={`cagr-bar ${index === 0 ? 'sam-bar' : 'sam-bar-secondary'}`}
                    style={{ backgroundColor: colors.sam }}
                    initial={{ width: 0 }}
                    animate={{ width: `${normalizeCAGR(market.sam.cagr)}%` }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                <span className="cagr-value">{market.sam.cagr}%</span>
              </motion.div>
            )}

            {visibleLayers.som && (
              <motion.div 
                className="cagr-row"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <span className="cagr-label">SOM</span>
                <div className="cagr-bar-container">
                  <motion.div 
                    className={`cagr-bar ${index === 0 ? 'som-bar' : 'som-bar-secondary'}`}
                    style={{ backgroundColor: colors.som }}
                    initial={{ width: 0 }}
                    animate={{ width: `${normalizeCAGR(market.som.cagr)}%` }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                <span className="cagr-value">{market.som.cagr}%</span>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  };

  return (
    <Card 
      className="market-matrix-viewer" 
      sx={{backgroundColor: 'var(--color-surface)'}}
      onMouseMove={handleMouseMove}
    >
      <CardContent>
        <div className="visibility-controls">
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLayers.tam}
                onChange={() => handleLayerToggle('tam')}
                sx={{
                  color: '#FED38C',
                  '&.Mui-checked': {
                    color: '#FED38C',
                  },
                }}
              />
            }
            label="TAM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLayers.sam}
                onChange={() => handleLayerToggle('sam')}
                sx={{
                  color: '#FDB544',
                  '&.Mui-checked': {
                    color: '#FDB544',
                  },
                }}
              />
            }
            label="SAM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLayers.som}
                onChange={() => handleLayerToggle('som')}
                sx={{
                  color: '#FD9800',
                  '&.Mui-checked': {
                    color: '#FD9800',
                  },
                }}
              />
            }
            label="SOM"
          />
        </div>

        <div className="markets-grid">
          {markets.map((market, index) => (
            <MarketCircleWithCagr 
              key={`market_${market.name}_${index}`} 
              market={market}
              index={index}
              visibleLayers={visibleLayers}
            />
          ))}
        </div>

        {/* Cursor Toast */}
        {toast.show && (
          <div 
            className="cursor-toast"
            style={{
              position: 'fixed',
              left: toast.x,
              top: toast.y,
              transform: `translate(0, -50%)`
            }}
          >
            {toast.text}
          </div>
        )}

        <div className="legend-container">
          <div className="market-size-legend">
            <div className="legend-item">
              <div className="legend-circle tam-legend"></div>
              <span>TAM</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle sam-legend"></div>
              <span>SAM</span>
            </div>
            <div className="legend-item">
              <div className="legend-circle som-legend"></div>
              <span>SOM</span>
            </div>
          </div>

          <div className="cagr-legend">
            CAGR bars show relative growth rates (min: {minCagr.toFixed(1)}% to max: {maxCagr.toFixed(1)}%)
            Bar length indicates relative growth rate. Longer bars = higher growth.
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const Glow21Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_21');  

  if (!process) {
    return <div>Process configuration not found</div>;
  }
  
  // Ensure we pass a properly structured object to MarketMatrixViewer
  const formattedData = {
    markets: Array.isArray(data?.markets) ? data.markets : []
  };
  
  return (
    <Box>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_21"
        sessionId={sessionId}
      />
      <MarketMatrixViewer data={formattedData} />
      <ViewFooter 
        onComplete={onComplete}
        buttonText={process?.footer?.continueText || "Continue"}
      />
    </Box>
  );
};

export default Glow21Processor;
