import React, { useState } from 'react';
import './Viewers.css';
import './glow_03_processor_script.css'
import processConfig from '../../config/processIndex.json';
import { Box, CircularProgress, Button } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import { ProcessChainManager } from '../../utils/processChain';
import { generateAndSaveTargetGroupImage } from '../../utils/processUtils';

const Glow03ProcessorScript = ({ data, onComplete, sessionId }) => {
  
  const process = processConfig.processes.find(p => p.id === 'glow_03');

  const [isLoading, setIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState(data);

  const [expandedGroups, setExpandedGroups] = useState({});
  const [expandedScenes, setExpandedScenes] = useState({});
  const [expandedFrames, setExpandedFrames] = useState({});

  const [hoverScene, setHoverScene] = useState()
  const [selectedScene, setSelectedScene] = useState()
  const [selectedFrame, setSelectedFrame] = useState()

  const [groupImages, setGroupImages] = useState({});
  const [loadingGroupImages, setLoadingGroupImages] = useState({});
  const [frameImages, setFrameImages] = useState({});
  const [loadingFrameImages, setLoadingFrameImages] = useState({});
  const [sceneImages, setSceneImages] = useState({});
  const [loadingSceneImages, setLoadingSceneImages] = useState({});

  React.useEffect(() => {
    if (!processedData?.glow_03_response && data) {
      const runInitialProcess = async () => {
        const cachedResponse = ProcessChainManager.getCachedResponse('glow_03');
        if (cachedResponse?.data?.glow_03_response) {
          console.log('Using cached glow_03 data');
          setProcessedData(cachedResponse.data);
          return;
        }

        setIsLoading(true);
        try {
          const response = await ProcessChainManager.runProcess('glow_03', sessionId, {
            previous_process: 'glow_02',
            previous_response: data
          });
          
          if (response?.status) {
            ProcessChainManager.updateCacheElement('glow_03', {
              data: response.data,
              timestamp: new Date().getTime()
            });
            setProcessedData(response.data);
          }
        } catch (error) {
          console.error('Error running glow_03:', error);
        } finally {
          setIsLoading(false);
        }
      };

      runInitialProcess();
    }
  }, [data, sessionId]);

  React.useEffect(() => {
    if (processedData?.glow_03_response) {
      Object.entries(processedData.glow_03_response).forEach(([groupId, group]) => {
        if (group.target_group && !groupImages[groupId]) {
          generateImageForGroup(group, groupId);
        }
        
        group.scenes?.forEach(scene => {
          const sceneKey = `${groupId}_${scene.scene_number}`;
          if (scene.scene_visuals_extensive && !sceneImages[sceneKey]) {
            generateImageForScene(scene, sceneKey);
          }
          scene.frames?.forEach(frame => {
            const frameKey = `${groupId}_${scene.scene_number}_${frame.frame_number}`;
            if (frame.full_visual_prompt && !frameImages[frameKey]) {
              generateImageForFrame(frame, frameKey, sessionId);
            }
          });
        });
      });
    }
  }, [processedData]);

  const generateImageForGroup = async (group, groupId) => {
    if (!group.target_group || groupImages[groupId]) return;
    
    setLoadingGroupImages(prev => ({ ...prev, [groupId]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        `Professional video scene for ${group.target_group} with ${group.video_base.style} style and ${group.video_base.color_scheme} color scheme`,
        `group_${groupId}`
      );
      
      setGroupImages(prev => ({
        ...prev,
        [groupId]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate group image:', error);
    } finally {
      setLoadingGroupImages(prev => ({ ...prev, [groupId]: false }));
    }
  };

  const generateImageForFrame = async (frame, frameKey, sessionId) => {
    if (!frame.full_visual_prompt || frameImages[frameKey]) return;
    
    setLoadingFrameImages(prev => ({ ...prev, [frameKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        frame.full_visual_prompt,
        `${sessionId}/frame_${frameKey}`
      );
      
      setFrameImages(prev => ({
        ...prev,
        [frameKey]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate frame image:', error);
    } finally {
      setLoadingFrameImages(prev => ({ ...prev, [frameKey]: false }));
    }
  };

  const generateImageForScene = async (scene, sceneKey) => {
    const sceneName = `scene_${scene.scene_number}_${scene.scene_title.toLowerCase().replace(/\s+/g, '_')}`;
    
    if (!scene.scene_visuals_extensive || sceneImages[sceneKey]) return;
    
    setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: true }));
    try {
      const existingPath = `https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/${sceneName}.jpeg`;
      
      setSceneImages(prev => ({
        ...prev,
        [sceneKey]: existingPath
      }));
    } catch (error) {
      console.error('Failed to generate scene image:', error);
    } finally {
      setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: false }));
    }
  };

  const toggleGroup = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const toggleScene = (sceneId, sceneNumber) => {
    setExpandedScenes(prev => ({
      ...prev,
      [sceneId]: !prev[sceneId]
    }));
    if(selectedScene === sceneNumber) setSelectedScene()
    else setSelectedScene(sceneNumber);
  };

  const toggleFrame = (frameId, frameNumber) => {
    setExpandedFrames(prev => ({
      ...prev,
      [frameId]: !prev[frameId]
    }));
    if(selectedFrame === frameNumber) setSelectedFrame()
    else setSelectedFrame(frameNumber);
  };

  const renderFrame = (frame, groupId, sceneId) => {
    const frameKey = `${groupId}_${sceneId}_${frame.frame_number}`;
    const isExpanded = expandedFrames[frameKey];
    const isSelected = frame.frame_number === selectedFrame;
    
    return (
      <div key={frameKey} className={`frame-card${selectedScene && selectedFrame ? (isSelected ? ' selected' : ' d-none') : ''} ${(!selectedScene && hoverScene === sceneId) ? ' hover' : ''}`}>
          <div className="frame-image">
            {loadingFrameImages[frameKey] ? (
              <CircularProgress size={24} />
            ) : frameImages[frameKey] ? (
              <img 
                src={frameImages[frameKey]} 
                alt={`Visual for Frame ${frame.frame_number}`}
                onClick={() => toggleFrame(frameKey, frame.frame_number)}
              />
            ) : (
              <Button 
                onClick={(e) => {
                  e.stopPropagation();
                  generateImageForFrame(frame, frameKey, sessionId);
                }}
                variant="outlined"
                size="small"
              >
                Generate Frame Visual
              </Button>
            )}
          </div>
      </div>
    );
  };

  const renderFrameDetail = (frameData) => {
    return (
      <div className="frame-details">
        {frameData.primary_person && (
          <div className="frame-item">
            <strong>Primary Person:</strong>
            <p>Description: {frameData.primary_person.description}</p>
            <p>Action: {frameData.primary_person.action}</p>
            <p>Expression: {frameData.primary_person.expression}</p>
          </div>
        )}
        
        {frameData.environment && (
          <div className="frame-item">
            <strong>Environment:</strong>
            <p>Setting: {frameData.environment.setting}</p>
            <p>Background: {frameData.environment.background}</p>
            <p>Props: {frameData.environment.props.join(', ')}</p>
          </div>
        )}
        
        {frameData.technical && (
          <div className="frame-item">
            <strong>Technical:</strong>
            <p>Camera: {frameData.technical.camera}</p>
            <p>Lighting: {frameData.technical.lighting}</p>
            {frameData.technical.color_grade && (
              <p>Color Grade: {frameData.technical.color_grade}</p>
            )}
          </div>
        )}
        
        {frameData.audio && (
          <div className="frame-item">
            <strong>Audio:</strong>
            <p>Narration: {frameData.audio.narration || 'None'}</p>
            <p>Music: {frameData.audio.music}</p>
          </div>
        )}
        
        <div className="frame-details">
          <div className="frame-item">
            <strong>Visual Prompt:</strong>
            <p>{frameData.full_visual_prompt}</p>
          </div>
          <p><strong>Duration:</strong> {frameData.duration}</p>
          <p><strong>Transition:</strong> {frameData.transition}</p>
        </div>
      </div>
    )
  }

  const renderScene = (sceneData, groupId) => {
    const sceneKey = `${groupId}_${sceneData.scene_number}`;
    const isExpanded = expandedScenes[sceneKey];
    const isSelected = sceneData.scene_number === selectedScene;
    
    return (
      <div key={sceneKey} className={`scene-card${selectedScene ? isSelected ? ' selected' : ' d-none' : ''}`}>
        <div className="scene-image">
          {loadingSceneImages[sceneKey] ? (
            <CircularProgress size={24} />
          ) : sceneImages[sceneKey] ? (
            <img 
              src={sceneImages[sceneKey]} 
              alt={`Visual for Scene ${sceneData.scene_number}`}
              onClick={() => toggleScene(sceneKey, sceneData.scene_number)}
              onMouseEnter={() => { setHoverScene(sceneData.scene_number) }}
              onMouseLeave={() => { setHoverScene()}}
            />
          ) : (
            <Button 
              onClick={() => generateImageForScene(sceneData, sceneKey)}
              variant="outlined"
              size="small"
            >
              Generate Scene Visual
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderSceneDetail = (sceneData) => {
    return (
      <div className="scene-details">
        <p><strong>Scene Length:</strong> {sceneData.scene_length}</p>
        <p><strong>Purpose:</strong> {sceneData.purpose}</p>
        <p><strong>Emotion:</strong> {sceneData.emotion}</p>
        <div className="scene-item">
          <strong>Visual Basics:</strong>
          <p>{sceneData.scene_visuals_basics}</p>
        </div>
        <div className="scene-item">
          <strong>Visual Details:</strong>
          <p>{sceneData.scene_visuals_extensive}</p>
        </div>
        <div className="scene-item">
          <strong>Background Audio:</strong>
          <p>{sceneData.scene_audio_background}</p>
        </div>
        <div className="scene-item">
          <strong>Success Metric:</strong>
          <p>{sceneData.success_metric}</p>
        </div>
      </div>
    )
  }

  const renderTargetGroup = (groupData, groupId) => {
    const isExpanded = expandedGroups[groupId];
    const selectedSceneData = selectedScene ? groupData.scenes.find(scene => scene.scene_number === selectedScene) : {};
    const selectedFrameData = (selectedFrame && !!selectedSceneData) ? selectedSceneData.frames?.find(frame => frame.frame_number === selectedFrame) : {};
    
    return (
      <div key={`group-${groupId}`} className="target-group glow_03">
        <div className="group-content" >
          <div className='group-card'>
            <div className="group-image">
              {loadingGroupImages[groupId] ? (
                <CircularProgress size={24} />
              ) : groupImages[groupId] ? (
                <img 
                  src={groupImages[groupId]} 
                  alt={`Visual for ${groupData.target_group}`}
                  onClick={() => toggleGroup(groupId)}
                />
              ) : (
                <Button 
                  onClick={() => generateImageForGroup(groupData, groupId)}
                  variant="outlined"
                  size="small"
                >
                  Generate Visual
                </Button>
              )}
            </div>
          </div>

          <div className="group-detail">
            <h4>Video Base Information</h4>
            <p><strong>Style:</strong> {groupData.video_base.style}</p>
            <p><strong>Duration:</strong> {groupData.video_base.target_duration_in_sec} seconds</p>
            <p><strong>Color Scheme:</strong> {groupData.video_base.color_scheme}</p>
          </div>
          
          <div className="group-detail">
            <h4>Audio Base</h4>
            <p><strong>Timbre:</strong> {groupData.video_base.audio_base.timbre}</p>
            <p><strong>Tempo:</strong> {groupData.video_base.audio_base.tempo}</p>
            <p><strong>Core Rhythm:</strong> {groupData.video_base.audio_base.core_rhythm}</p>
            <p><strong>Harmonic Base:</strong> {groupData.video_base.audio_base.harmonic_base}</p>
          </div>
        </div>
        
        {isExpanded && (
          <div className="scene-content">
            {groupData.scenes.map(scene => renderScene(scene, groupId))}
            {selectedScene && renderSceneDetail(selectedSceneData)}
          </div>
        )}
        
        {isExpanded && !selectedScene && (
          <div className="frame-content">
            {groupData.scenes.map(scene => scene.frames?.map(frame => renderFrame(frame, groupId, scene.scene_number)))}
          </div>
        )}
        {isExpanded && selectedScene && selectedSceneData && (
          <div className="frame-content">
            {selectedSceneData.frames?.map(frame => renderFrame(frame, groupId, selectedSceneData.scene_number))}
            {selectedScene && selectedFrame && selectedFrameData && renderFrameDetail(selectedFrameData)}
          </div>
        )}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="process-viewer">
        <Box>
          <ViewerHeader 
            title={process.info.title}
            subtitle={process.info.description}
            processId="glow_03"
            sessionId={sessionId}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '200px' 
          }}>
            <CircularProgress />
          </Box>
        </Box>
      </div>
    );
  }

  if (!processedData) {
    return <div>No data available</div>;
  }

  return (
    <div className="process-viewer">
      <Box>
        <ViewerHeader 
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_03"
          sessionId={sessionId}
        />
        {processedData?.glow_03_response && 
          Object.entries(processedData.glow_03_response).map(([groupId, group]) => 
            renderTargetGroup(group, groupId)
          )}
        <ViewFooter 
          onComplete={onComplete}
          buttonText={process.footer.continueText}
        />
      </Box>
    </div>
  );
};

export default Glow03ProcessorScript;