import React from 'react';
import { Box, Button } from '@mui/material';
import { FaPlay } from 'react-icons/fa';

const ViewFooter = ({ 
  onComplete,
  buttonText,
  isDisabled = false,
  showButton = true,
  customButtons = null
}) => {
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'center',
      gap: 2,
      mt: 4,
      pt: 3,
      borderTop: '1px solid var(--border-color)',
      width: '100%'
    }}>
      {customButtons}
      {showButton && (
        <Button
          variant="contained"
          onClick={onComplete}
          disabled={isDisabled}
          startIcon={<FaPlay />}
          sx={{
            color: 'var(--button-text)',
            backgroundColor: 'var(--button-bg)',
            '&:hover': {
              backgroundColor: 'var(--button-hover)'
            },
            minWidth: '200px',
            height: '48px'
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default ViewFooter;