import React from 'react';
import PricingDashboard from './glow_11_viewer_prodprice';
import ViewerHeader from '../common/ViewerHeader';
import processConfig from '../../config/processIndex.json';
import ViewFooter from '../common/ViewFooter';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';

const Glow11Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_11');

  // Extract the pricing data from the response
  const pricingData = {
    productCostBreakdown: data?.product_cost_breakdown || [],
    pricingData: data?.market_pricing?.map(mp => ({
      segment: mp.segment,
      marketPrice: mp.price,
      suggestedPrice: data?.suggested_price_points?.find(spp => spp.segment === mp.segment)?.price || 0
    })) || [],
    customerMetrics: data?.customer_metrics || { cac: {}, ltv: {} }
  };

  const handleSave = () => {
    console.log('Saving draft...');
    // Implement save functionality
  };

  const customButtons = (
    <Button 
      variant="outlined" 
      onClick={handleSave}
      startIcon={<FaSave />}
    >
      Save Draft
    </Button>
  );

  return (
    <Box>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_11"
        sessionId={sessionId}
      />
      <PricingDashboard data={pricingData} />
      <ViewFooter 
        onComplete={onComplete}
        buttonText={process.footer.continueText}
        customButtons={customButtons}
      />
    </Box>
  );
};

export default Glow11Processor;