import React from 'react';
import TamSamSomViewer from './glow_10_viewer_tamsamsom';
import ViewerHeader from '../common/ViewerHeader';
import processConfig from '../../config/processIndex.json';
import ViewFooter from '../common/ViewFooter';
import { Box } from '@mui/material';

const Glow10Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_10');
  
  return (
    <Box>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_10"
        sessionId={sessionId}
      />
      <TamSamSomViewer data={data?.glow_10_response?.market_analysis || data?.market_analysis || {}} />
      <ViewFooter 
        onComplete={onComplete}
        buttonText={process.footer.continueText}
      />
    </Box>
  );
};

export default Glow10Processor;