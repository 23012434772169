import React from 'react';
import PropTypes from 'prop-types';
import './CreditFlag.css'; // Optional: for custom styles

const CreditFlag = ({ credits, subscriptionPlan }) => {
    return (
      <div id="subscriptions-center" className={`credit-flag ${subscriptionPlan}`}>
        <span id="subscriptions-info">{(subscriptionPlan === 'register' || subscriptionPlan === 'guest') ? 'Credits' : subscriptionPlan}</span>
        <span id="credit-balance">{(subscriptionPlan === 'register' || subscriptionPlan === 'guest') ? credits : ''}</span>
      </div>
    );
};

CreditFlag.propTypes = {
    credits: PropTypes.number.isRequired,
    subscriptionPlan: PropTypes.string.isRequired,
};

export default CreditFlag;