class ProcessCache {
  constructor(ttl = 3600000) { // Default TTL is set to 1 hour
    this.cache = {};
    this.ttl = ttl;
    this.loadCache();
    // window.addEventListener('beforeunload', this.saveCache);
  }

  set(sessionId, data) {
    const expirationTime = Date.now() + this.ttl;
    this.cache[sessionId] = { data, expirationTime };
    this.saveCache();
  }

  get(sessionId) {
    const cachedItem = this.cache[sessionId];
    if (cachedItem) {
      if (Date.now() < cachedItem.expirationTime) {
        return cachedItem.data;
      } else {
        // Item has expired
        delete this.cache[sessionId];
        this.saveCache();
      }
    }
    return null; // Return null if no valid cache found
  }

  clear() {
    this.cache = {};
    this.saveCache();
  }

  saveCache = () => {
    localStorage.setItem('processCache', JSON.stringify(this.cache));
  }

  loadCache = () => {
    const cachedData = localStorage.getItem('processCache');
    if (cachedData) {
      this.cache = JSON.parse(cachedData);
      // Remove expired items
      for (const sessionId in this.cache) {
        if (Date.now() >= this.cache[sessionId].expirationTime) {
          delete this.cache[sessionId];
        }
      }
      this.saveCache();
    }
  }
}
export default ProcessCache;