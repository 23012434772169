import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  Grid,
  styled,
  LinearProgress,
  IconButton,
  CircularProgress
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';

// Styled components
const StepIndicator = styled(Box)(({ theme, active, completed }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: completed === 'true' ? theme.palette.success.main : 
                 active === 'true' ? '#FD9800' : 
                 theme.palette.grey[200],
  color: (completed === 'true' || active === 'true') ? theme.palette.common.white : theme.palette.text.secondary,
}));

const OptionCard = styled(Button)(({ theme, selected, recommended }) => ({
  width: '100%',
  maxWidth: '100%',
  height: 'auto',
  padding: 0,
  textAlign: 'left',
  border: `2px solid ${selected ? '#FD9800' : recommended ? '#2e7d32' : theme.palette.divider}`,
  borderRadius: '10px',
  borderWidth: recommended ? '3px' : '2px',
  backgroundColor: selected ? '#FFF4E6' : theme.palette.background.paper,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  transform: selected ? 'scale(1.02)' : 'scale(1)',
  transition: 'all 0.2s ease',
  overflow: 'hidden',
  boxShadow: selected ? '0 4px 8px rgba(253, 152, 0, 0.15)' : 'none',
  '&:hover': {
    backgroundColor: selected ? '#FFF4E6' : theme.palette.action.hover,
    transform: 'scale(1.02)',
  },
  '& .image-container': {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    marginBottom: 0,
  },
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
    zIndex: 1
  }
}));

const RECOMMENDATIONS_BY_VIDEO_TYPE = {
  // Marketing/Commercial
  product_validation: {
    movement: 'static',
    editing: 'classical',
    framing: 'environmental',
    colorMood: 'earthy'
  },
  tv_ad: {
    movement: 'dynamic',
    editing: 'quick',
    framing: 'balanced',
    colorMood: 'vibrant'
  },
  product: {
    movement: 'floating',
    editing: 'classical',
    framing: 'environmental',
    colorMood: 'technical'
  },
  social: {
    movement: 'dynamic',
    editing: 'quick',
    framing: 'intimate',
    colorMood: 'vibrant'
  },
  
  // Educational
  software: {
    movement: 'static',
    editing: 'classical',
    framing: 'technical',
    colorMood: 'minimal'
  },
  howto: {
    movement: 'steady',
    editing: 'classical',
    framing: 'intimate',
    colorMood: 'minimal'
  },
  explain: {
    movement: 'floating',
    editing: 'quick',
    framing: 'balanced',
    colorMood: 'technical'
  },

  // Personal/Social
  story: {
    movement: 'dynamic',
    editing: 'quick',
    framing: 'intimate',
    colorMood: 'vibrant'
  }
};

const steps = [
  {
    id: 'movement',
    name: "Camera Movement & Style",
    description: "How should the camera move and behave?",
    options: [
      { id: "steady", name: "Steady & Controlled", desc: "Steadicam, elegant movements, professional stabilization", image: "/images/Cam_steady_ultra_realistic_illustration_of_cinematographer_u_7201623d-8e81-460a-a334-c70be36ad2e5_3 (1).jpg" },
      { id: "dynamic", name: "Dynamic & Handheld", desc: "Documentary style, reactive, authentic feel", image: "/images/cam_dynamic_Raw__Reactive_ultra_realistic_illustration_of_new_a8e0caa5-b1af-4eec-a0aa-8659582b59ef_2.jpg" },
      { id: "static", name: "Static & Composed", desc: "Tripod-based, precise framing, intentional stillness", image: "/images/cam_Static__Composed_ultra_realistic_illustration_of__05037fb1-f978-4abc-a47e-00277a32335a_2.jpg" },
      { id: "floating", name: "Floating & Ethereal", desc: "Gimbal-based, dreamlike flowing movements", image: "/images/cam_Floating__Ethereal_ultra_realistic_illustration_o_d3c7e23b-6c12-4f13-88ee-4c702cc0987a_1.jpg" },
      { id: "raw", name: "Raw & Reactive", desc: "Run-and-gun style, journalistic approach", image: "/images/cam_raw_ultra_realistic_illustration_of_news_cameraman_in_f19626b4-2665-4471-b700-6687526b099c_3.jpg" },
      { id: "technical", name: "Technical & Complex", desc: "Crane shots, sophisticated camera movements", image: "/images/cam_Technical__Complex_ultra_realistic_illustration_o_20943cc8-1145-4cf5-982c-083b2bb8a645_1.jpg" },
      { id: "hybrid", name: "Hybrid & Mixed", desc: "Combining multiple techniques strategically", image: "/images/cam_hybrid_ultra_realistic_illustration_of_multiple_camera_s_3a4ab3af-b685-4219-a2cc-c39d018a1559_2.jpg" },
      { id: "pov", name: "POV & Immersive", desc: "First-person perspective, engaging viewer directly", image: "/images/cam_POV__Immersive_ultra_realistic_illustration_of_he_016b3c59-ca81-418d-b3e2-63b488b32a08_0.jpg" },
      { id: "architectural", name: "Architectural & Geometric", desc: "Structure-focused movements, precise patterns", image: "/images/cam_Architectural__Geometric_ultra_realistic_illustra_4042bb18-29bf-4a80-a93f-b1ec858423fb_0.jpg" }
    ]
  },
  {
    id: 'editing',
    name: "Editing Style",
    description: "How should the story be cut together?",
    options: [
      { id: "quick", name: "Quick & Punchy", desc: "Fast cuts, high energy, dynamic pacing", image: "/images/editing_Quick__Punchy_ultra_realistic_illustration_of_fas_55409b15-9690-47a3-9181-11270f4a1232_1.jpg" },
      { id: "slow", name: "Slow & Deliberate", desc: "Long takes, contemplative moments", image: "/images/editing_Slow__Deliberate_ultra_realistic_illustration_of__20bace3f-d89c-4b59-8d2e-9e0d8958a634_0.jpg" },
      { id: "rhythmic", name: "Rhythmic & Musical", desc: "Beat-driven editing, synchronized cuts", image: "/images/editing_Rhythmic__Musical_ultra_realistic_illustration_of_6e7713cf-5e8f-4907-a021-b8eb1014da72_1.jpg" },
      { id: "classical", name: "Classical & Narrative", desc: "Traditional storytelling, seamless transitions", image: "/images/editing_Classical__Narrative_ultra_realistic_illustration_e575644d-cdec-45d0-b164-ade572ea9446_1.jpg" },
      { id: "jumpcut", name: "Jump-cut & Experimental", desc: "Modern, dynamic, unconventional", image: "/images/editing_Jump-cut__Experimental_ultra_realistic_illustrati_ff11a14a-4a55-48bf-9ea8-3af87472df8c_0.jpg" },
      { id: "seamless", name: "Seamless & Invisible", desc: "Hidden transitions, flowing narrative", image: "/images/editing_Seamless__Invisible_ultra_realistic_illustration__e6dc91e7-de5f-4d2e-9a52-6312c4e8d088_2.jpg" },
      { id: "montage", name: "Montage & Parallel", desc: "Multiple storylines, thematic connections", image: "/images/editing_Montage__Parallel_ultra_realistic_illustration_of_1d6aba6d-1cae-4d02-94ed-aec97f223262_2.jpg" },
      { id: "timebased", name: "Time-based", desc: "Slow-motion, speed ramping, temporal play", image: "/images/editing_Time-based_ultra_realistic_illustration_of_time_m_e529a15a-3646-4a81-83c4-0228ad6ae840_2.jpg" },
      { id: "nonlinear", name: "Non-linear & Fragment", desc: "Memory-like sequences, emotional pacing", image: "/images/editing_Non-linear__Fragment_ultra_realistic_illustration_690f2af6-c15c-4689-b135-3ad67374c62d_2.jpg" }
    ]
  },
  {
    id: 'framing',
    name: "Visual Space & Framing",
    description: "How should scenes be composed?",
    options: [
      { id: "intimate", name: "Intimate & Close", desc: "Close-ups, personal connection, emotional", image: "/images/visu_Intimate__Close_ultra_realistic_illustration_of_e_f1973e13-efbb-46ab-ba73-86cf7b389e8b_1.jpg" },
      { id: "wide", name: "Wide & Epic", desc: "Landscape style, grand scale, sweeping views", image: "/images/visu_Wide__Epic_ultra_realistic_illustration_of_anamor_3e13f037-d201-43e7-8dbf-e6ccb425cc1c_0.jpg" },
      { id: "balanced", name: "Balanced & Symmetrical", desc: "Precise composition, formal arrangement", image: "/images/visu_Balanced__Symmetrical_ultra_realistic_illustratio_c0c7ea50-7728-4458-bd44-eacc6614aa82_1.jpg" },
      { id: "abstract", name: "Abstract & Artistic", desc: "Unique angles, creative framing", image: "/images/visu_Abstract__Artistic_ultra_realistic_illustration_o_353a5733-fa69-43c6-8bd8-2f666d3dba84_3.jpg" },
      { id: "environmental", name: "Environmental & Contextual", desc: "Location-focused, scene-setting", image: "/images/visu_Environmental__Contextual_ultra_realistic_illustr_5879a2d0-92f9-4023-a789-f2a5393a2626_1.jpg" },
      { id: "dynamic", name: "Dynamic & Layered", desc: "Multiple planes, depth-focused", image: "/images/visu_Dynamic__Layered_ultra_realistic_illustration_of__74d54804-abea-48e4-97d9-5566129e33aa_2.jpg" },
      { id: "negative", name: "Negative Space Focus", desc: "Minimalist approach, strategic emptiness", image: "/images/visu_Negative_Space_Focus_ultra_realistic_illustration_813d36a5-db0b-4839-aaa3-c82f07228276_1.jpg" },
      { id: "dutch", name: "Dutch & Angular", desc: "Tilted perspectives, dramatic tension", image: "/images/visu_Dutch__Angular_ultra_realistic_illustration_of_ti_5b2b2e52-8acc-4ee5-9985-fc41504fc9b5_0.jpg" }
    ]
  },
  {
    id: 'colorMood',
    name: "Color & Atmosphere",
    description: "What mood should your color palette convey?",
    options: [
      { id: "vibrant", name: "Vibrant & Electric", desc: "Neon-lit, hyper-saturated, high energy", image: "/images/colour_athmo_Vibrant__Electric_ultra_realistic_illustration_of_2605b775-c445-450f-9c59-451150bab789_2.jpg" },
      { id: "vintage", name: "Muted & Vintage", desc: "Retro tones, warm, nostalgic", image: "/images/colour_athmo_Muted__Vintage_ultra_realistic_illustration_of_fi_5ad92d06-ff03-4d43-b650-f257a6f966f5_2.jpg" },
      { id: "noir", name: "High Contrast Noir", desc: "Deep shadows, dramatic blacks", image: "/images/colour_athmo_High_Contrast_Noir_ultra_realistic_illustration_o_0218e012-bc22-4c97-af96-96259595734b_2.jpg" },
      { id: "earthy", name: "Natural & Earthy", desc: "Organic tones, natural light", image: "/images/colour_athmo_Natural__Earthy_ultra_realistic_illustration_of_g_aacf397b-7661-4454-8cd6-cd094077ddda_0.jpg" },
      { id: "ethereal", name: "Pastel & Ethereal", desc: "Soft colors, delicate, dreamy", image: "/images/colour_athmo_Pastel__Ethereal_ultra_realistic_illustration_of__05a86def-c41f-4412-8a91-29aedb9ce7b0_3.jpg" },
      { id: "graphic", name: "Bold & Graphic", desc: "Strong colors, striking contrasts", image: "/images/colour_athmo_Bold__Graphic_ultra_realistic_illustration_of_urb_c0148eae-80b6-47c9-bfac-e464523f7b57_1.jpg" },
      { id: "golden", name: "Golden & Romantic", desc: "Warm sunset tones, magical", image: "/images/colour_athmo_Golden__Romantic_ultra_realistic_illustration_of__fe405153-745e-4c09-b7e1-ebfeeba14501_3.jpg" },
      { id: "technical", name: "Cool & Technical", desc: "Clean blues, precise, modern", image: "/images/colour_athmo_Cool__Technical_ultra_realistic_illustration_of_s_f9fb2eca-36b7-462d-bc69-e87028efd359_3.jpg" },
      { id: "stormy", name: "Dramatic & Stormy", desc: "Moody atmosphere, intense", image: "/images/colour_athmo_ultra_realistic_illustration_of_lighthouse_keeper_c6a4719b-75e4-466a-a061-76e7aafd425e_3.jpg" },
      { id: "minimal", name: "Minimalist & Clean", desc: "Pure tones, simple, elegant", image: "/images/colour_athmo_Minimalist__Clean_ultra_realistic_illustration_of_9a8adc3c-856d-4da9-a482-8d26269863e6_0.jpg" },
      { id: "opulent", name: "Rich & Opulent", desc: "Jewel tones, luxurious", image: "/images/colour_athmo_Rich__Opulent_ultra_realistic_illustration_of_roy_2010738c-d6cf-4181-b41f-563efd94d9b9_1.jpg" },
      { id: "neon", name: "Neon & Night", desc: "Night colors, urban glow", image: "/images/colour_athmo_Neon__Night_ultra_realistic_illustration_of_stree_a4f59503-d54d-4014-a8fc-160e8bc11f19_3.jpg" }
    ]
  },
  {
    id: 'confirmation',
    name: "Review & Confirm",
    description: "Review your selected style choices",
    options: []
  }
];

const DEFAULT_VIDEO_TYPE = {
  id: 'product_validation',
  categoryId: 'mkt',
  name: "Product Validation",
  dur: "2m",
  status: 2
};

// Add this sticky header component
const StickyStepHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'var(--color-surface)',
  padding: theme.spacing(2),
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '& .MuiTypography-h5': {
    color: '#FD9800',
    fontWeight: 600,
    marginBottom: theme.spacing(0.5)
  },
  '& .MuiTypography-subtitle1': {
    color: theme.palette.text.secondary
  }
}));

const Glow63Processor = ({ prevData, data, onComplete }) => {
  
  if (!prevData?.selectedType) {
    console.error('No video type selected from glow_62, using default: Product Validation');
  } else {
    console.log('Using video type from glow_62:', prevData.selectedType);
  }

  const process = processConfig.processes.find(p => p.id === 'glow_63');
  const [currentStep, setCurrentStep] = useState(Object.keys(data).length);
  const [selections, setSelections] = useState( data ? data : {});
  const [loadedImages, setLoadedImages] = useState({});
  
  const scrollToTop = () => {
    const container = document.getElementById('glow-63-scroll-container');
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleSelection = (optionId) => {
    setSelections(prev => ({
      ...prev,
      [steps[currentStep].id]: optionId
    }));
    
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
      scrollToTop();
    }
  };

  const handleComplete = () => {
    onComplete({
      processId: 'glow_63',
      data: {
        glow_63_response: selections
      }
    });
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
      scrollToTop();
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
      scrollToTop();
    }
  };

  const currentStepData = steps[currentStep];
  const isLastStep = currentStep === steps.length - 1;
  const isColorStep = currentStepData.id === 'colorMood';

  useEffect(() => {
    currentStepData.options.forEach(option => {
      const img = new Image();
      img.onerror = () => console.error(`Failed to load: ${option.image}`);
      img.src = option.image;
    });
  }, [currentStep, currentStepData]);

  const getRecommendation = (stepId, optionId) => {
    const videoType = prevData?.selectedType?.id || DEFAULT_VIDEO_TYPE.id;
    // console.log('Checking recommendation for:', {
    //   videoType,
    //   currentStep: stepId,
    //   optionId,
    //   recommendedValue: RECOMMENDATIONS_BY_VIDEO_TYPE[videoType]?.[stepId]
    // });
    
    const recommendations = RECOMMENDATIONS_BY_VIDEO_TYPE[videoType];
    return recommendations?.[stepId] === optionId;
  };

  return (
    <Box sx={{ 
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden' // Prevent double scrollbars
    }}>
      <ViewerHeader 
        title={process.name}
        sx={{ 
          backgroundColor: '#FD9800',
          color: 'white'
        }}
      />
      
      {/* This is our main scrollable container */}
      <Box 
        id="glow-63-scroll-container" // Add an ID for easier targeting
        sx={{ 
          flex: 1,
          overflow: 'auto',
          backgroundColor: 'var(--color-surface)'
        }}
      >
        {/* Horizontal Selection Summary */}
        <Box sx={{ p: 3 }}>
          <Grid container spacing={1} alignItems="center">
            {steps.map((step, index) => {
              const selectedOption = selections[step.id] ? 
                step.options.find(o => o.id === selections[step.id]) : 
                null;
              const isConfirmationStep = step.id === 'confirmation';
              const isCurrentStepConfirmation = currentStepData.id === 'confirmation';

              return (
                <Grid 
                  item 
                  xs={isConfirmationStep ? 1 : 2.75}
                  key={step.id}
                  sx={isConfirmationStep ? {
                    height: '100%',
                    display: 'flex',
                    '& .MuiCard-root': {
                      height: '100%',
                      minHeight: '200px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }
                  } : {}}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      opacity: selections[step.id] || index <= currentStep ? 1 : 0.5,
                      transition: 'all 0.3s ease',
                      height: '100%'
                    }}
                  >
                    {/* Step Number */}
                    <StepIndicator
                      active={currentStep === index ? 'active' : ''}
                      completed={index < currentStep ? 'true' : 'false'}
                    >
                      {index < currentStep ? (
                        <CheckIcon fontSize="small" />
                      ) : (
                        index + 1
                      )}
                    </StepIndicator>

                    {/* Selection Card */}
                    {!isConfirmationStep && (
                      <Card 
                        elevation={0}
                        onClick={() => {
                          if (selections[step.id] || index <= currentStep) {
                            setCurrentStep(index);
                          }
                        }}
                        sx={{ 
                          width: '100%',
                          mt: 1,
                          backgroundColor: 'background.default',
                          border: 1,
                          borderColor: 'divider',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          cursor: (selections[step.id] || index <= currentStep) ? 'pointer' : 'default',
                          '&:hover': (selections[step.id] || index <= currentStep) ? {
                            boxShadow: 1,
                            borderColor: '#FD9800'
                          } : {}
                        }}
                      >
                        <Box sx={{ 
                          position: 'relative',
                          flexGrow: 1,
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                          {selectedOption ? (
                            <Box sx={{ 
                              p: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%'
                            }}>
                              <Box 
                                sx={{ 
                                  width: '100%',
                                  paddingTop: '75%',
                                  position: 'relative',
                                  overflow: 'hidden',
                                  mb: 1,
                                  borderRadius: 1
                                }}
                              >
                                <img 
                                  src={selectedOption.image} 
                                  alt={selectedOption.name}
                                  style={{ 
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                  }}
                                />
                              </Box>
                              <Box sx={{ 
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}>
                                <Typography 
                                  variant="subtitle2" 
                                  align="center" 
                                  sx={{
                                    color: '#FD9800',
                                    fontWeight: 'medium'
                                  }}
                                >
                                  {selectedOption.name}
                                </Typography>
                                {isCurrentStepConfirmation && (
                                  <Typography 
                                    variant="caption" 
                                    color="text.secondary"
                                    align="center"
                                  >
                                    {selectedOption.desc}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ 
                              p: 1, 
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                              position: 'relative'
                            }}>
                              {/* Category Overlay for empty state */}
                              <Box 
                                sx={{ 
                                  position: 'absolute',
                                  top: 8,
                                  left: 8,
                                  backgroundColor: 'rgba(0,0,0,0.6)',
                                  color: 'white',
                                  padding: '4px 8px',
                                  borderRadius: 1,
                                  fontSize: '0.75rem'
                                }}
                              >
                                {step.name}
                              </Box>
                              <Typography 
                                variant="caption" 
                                color="text.secondary"
                                align="center"
                                sx={{ fontStyle: 'italic' }}
                              >
                                {index === currentStep ? (
                                  "Select below"
                                ) : (
                                  index < currentStep ? "Pending..." : "Coming up"
                                )}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Card>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        {/* Sticky header will now work because parent is scrollable */}
        <StickyStepHeader>
          <Typography variant="h5">
            {currentStepData.name}
          </Typography>
          <Typography variant="subtitle1">
            {currentStepData.description}
          </Typography>
        </StickyStepHeader>

        {/* Content */}
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2}>
            {currentStepData.options.map(option => (
              <Grid item xs={6} sm={4} md={3} key={option.id} sx={{ px: 1 }}>
                <OptionCard
                  onClick={() => handleSelection(option.id)}
                  selected={selections[currentStepData.id] === option.id}
                  recommended={(getRecommendation(currentStepData.id, option.id)) ? 'true' : 'false'}
                >
                  {getRecommendation(currentStepData.id, option.id) && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: '#2e7d32',
                        color: 'white',
                        padding: '4px 8px',
                        borderRadius: 1,
                        fontSize: '0.7rem',
                        fontWeight: 'medium',
                        zIndex: 2,
                        boxShadow: 1,
                        lineHeight: 1.2,
                        textAlign: 'center'
                      }}
                    >
                      Recommended for<br />
                      {prevData?.selectedType?.name || DEFAULT_VIDEO_TYPE.name}
                    </Box>
                  )}
                  <Box className="image-container">
                    <img 
                      src={option.image}
                      alt={option.name}
                      loading="lazy"
                      onError={(e) => {
                        console.error(`Failed to load image: ${option.image}`);
                        e.target.style.opacity = 0;
                      }}
                      style={{
                        opacity: 1
                      }}
                    />
                  </Box>
                  <Box sx={{ p: 0.5 }}>
                    <Typography 
                      variant="subtitle2" 
                      noWrap
                      sx={{ 
                        color: '#FD9800',
                        fontWeight: 500,
                        fontSize: '0.95rem',
                        letterSpacing: '0.01em',
                        lineHeight: 1.4,
                        fontFamily: 'var(--font-primary)'
                      }}
                    >
                      {option.name}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        lineHeight: 1.2
                      }}
                    >
                      {option.desc}
                    </Typography>
                  </Box>
                </OptionCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <ViewFooter 
        showButton={isLastStep && currentStepData.id === 'confirmation'}
        onComplete={handleComplete}
        buttonText={process.footer.continueText}
      />
    </Box>
  );
};

export default Glow63Processor;