import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  TextField,
  IconButton,
  Grid,
  Chip,
  styled,
  ThemeProvider
} from '@mui/material';
import {
  Search as SearchIcon,
  ExpandMore as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon,
  AccessTime as ClockIcon,
  Lock as LockIcon,
  MovieCreation as EntertainmentIcon,
  School as EducationIcon,
  Campaign as MarketingIcon,
  Person as PersonalIcon,
  Business as ProfessionalIcon,
  Article as JournalismIcon
} from '@mui/icons-material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import processConfig from '../../config/processIndex.json';

// Styled components to replace Tailwind classes
const VideoTypeButton = styled(Button)(({ theme, active }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  backgroundColor: active ? '#FD9800' : theme.palette.background.paper,
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? '#E68A00' : theme.palette.action.hover,
  },
  '& .duration': {
    fontSize: '0.75rem',
    color: active ? theme.palette.primary.contrastText : theme.palette.text.secondary,
  }
}));

const CategoryRow = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const CATEGORY_ICONS = {
  ent: EntertainmentIcon,
  edu: EducationIcon,
  mkt: MarketingIcon,
  per: PersonalIcon,
  pro: ProfessionalIcon,
  jou: JournalismIcon
};

const isCategoryComingSoon = (category) => {
  return Object.values(category.types).every(type => type.status === 1);
};

const Glow62Processor = ({ data, onComplete }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_62');
  
  const [searchTerm, setSearchTerm] = useState('');
  const DEFAULT_TYPE = {
    id: 'product_validation',
    categoryId: 'mkt',
    name: "Product Validation",
    dur: "2m",
    status: 2
  };

  // Initialize with default or saved selection
  const [selectedType, setSelectedType] = useState(() => {
    return data?.selectedType || DEFAULT_TYPE;
  });

  // Initialize expanded categories
  const [expandedCategories, setExpandedCategories] = useState(() => {
    const categoryId = data?.selectedType?.categoryId || DEFAULT_TYPE.categoryId;
    return {
      [categoryId]: true
    };
  });

  // VIDEO_INDEX moved to processConfig or separate config file
  const VIDEO_INDEX = {
    "header": {
      "version": "1.0",
      "status_codes": {
        "0": "not_available",
        "1": "coming_soon",
        "2": "available"
      }
    },
    "categories": {
      "ent": {
        "name": "Entertainment",
        "types": {
          "feat_film": { "name": "Feature Films", "dur": "120m", "status": 1 },
          "short_film": { "name": "Short Films", "dur": "30m", "status": 1 },
          "tv_series": { "name": "TV Series", "dur": "45m", "status": 1 },
          "web_series": { "name": "Web Series", "dur": "15m", "status": 1 },
          "music_vid": { "name": "Music Videos", "dur": "5m", "status": 1 },
          "gaming": { "name": "Gaming Content", "dur": "30m", "status": 1 }
        }
      },
      "edu": {
        "name": "Educational",
        "types": {
          "software": { "name": "Software Tutorials", "dur": "30s", "status": 2 },
          "academic": { "name": "Academic Lectures", "dur": "45m", "status": 1 },
          "training": { "name": "Training Videos", "dur": "15m", "status": 1 },
          "howto": { "name": "How-to Guides", "dur": "30s", "status": 2 },
          "edu_doc": { "name": "Educational Documentary", "dur": "30m", "status": 1 },
          "explain": { "name": "Explainer Videos", "dur": "45s", "status": 2 }
        }
      },
      "mkt": {
        "name": "Marketing/Commercial",
        "types": {
          "product_validation": { "name": "Product Validation", "dur": "30s", "status": 2 },
          "tv_ad": { "name": "TV Commercials", "dur": "30s", "status": 2 },
          "brand": { "name": "Brand Stories", "dur": "2m", "status": 1 },
          "product": { "name": "Product Demos", "dur": "45s", "status": 2 },
          "social": { "name": "Social Media Ads", "dur": "30s", "status": 2 },
          "corp": { "name": "Corporate Communications", "dur": "5m", "status": 1 },
          "event": { "name": "Event Videos", "dur": "30m", "status": 1 }
        }
      },
      "per": {
        "name": "Personal/Social",
        "types": {
          "vlog": { "name": "Vlogs", "dur": "10m", "status": 1 },
          "story": { "name": "Social Stories", "dur": "30s", "status": 2 },
          "live": { "name": "Live Streams", "dur": "30m", "status": 1 },
          "family": { "name": "Family Videos", "dur": "5m", "status": 1 },
          "review": { "name": "Review Videos", "dur": "3m", "status": 1 },
          "challenge": { "name": "Challenge Videos", "dur": "2m", "status": 1 }
        }
      },
      "pro": {
        "name": "Professional/Technical",
        "types": {
          "medical": { "name": "Medical Procedures", "dur": "15m", "status": 1 },
          "legal": { "name": "Legal Depositions", "dur": "120m", "status": 1 },
          "tech_doc": { "name": "Technical Documentation", "dur": "10m", "status": 1 },
          "eng": { "name": "Engineering Videos", "dur": "20m", "status": 1 },
          "real_est": { "name": "Real Estate Tours", "dur": "5m", "status": 1 },
          "security": { "name": "Security Footage", "dur": "30m", "status": 1 }
        }
      },
      "jou": {
        "name": "Journalistic/Documentary",
        "types": {
          "news": { "name": "News Reports", "dur": "3m", "status": 1 },
          "doc_film": { "name": "Documentary Films", "dur": "60m", "status": 1 },
          "interview": { "name": "Interview Series", "dur": "20m", "status": 1 },
          "coverage": { "name": "Event Coverage", "dur": "15m", "status": 1 },
          "bts": { "name": "Behind-the-scenes", "dur": "10m", "status": 1 },
          "historical": { "name": "Historical Documentation", "dur": "30m", "status": 1 }
        }
      }
    }
  };

  const getFilteredTypes = () => {
    const searchLower = searchTerm.toLowerCase();
    return Object.entries(VIDEO_INDEX.categories).map(([catId, category]) => {
      const types = Object.entries(category.types)
        .map(([typeId, type]) => ({
          id: typeId,
          categoryId: catId,
          ...type
        }))
        .filter(type => type.name.toLowerCase().includes(searchLower));

      return {
        id: catId,
        name: category.name,
        types,
        isAvailable: types.some(type => type.status === 2)
      };
    }).filter(cat => cat.types.length > 0);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  const toggleAll = (expand) => {
    const newState = {};
    Object.keys(VIDEO_INDEX.categories).forEach(category => {
      newState[category] = expand;
    });
    setExpandedCategories(newState);
  };

  const handleContinue = () => {
    if (selectedType) {
      onComplete({
        processId: 'glow_62',
        data: {
          glow_62_response: {
            selectedType: selectedType,
          }
        }
      });
    }
  };

  const handleTypeSelection = (type) => {
    if (type.status === 2) {
      setSelectedType(type);
    }
  };

  return (
    <>
      <ViewerHeader 
        title="Create Your Video"
        subtitle="Choose from our variety of video types and start creating your professional video in minutes"
        processId="glow_62"
      />
      <Box sx={{ p: 3, backgroundColor: 'var(--color-surface)' }}>
        <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
          <CardContent>
            <Stack spacing={3}>
              {/* Search and Controls */}
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ position: 'relative', flexGrow: 1, maxWidth: 'md' }}>
                  <TextField
                    fullWidth
                    placeholder="Search for specific types..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                      ),
                      sx: { pl: 1 }
                    }}
                  />
                </Box>
                <Stack direction="row" spacing={1}>
                  <Button 
                    variant="outlined"
                    size="small"
                    onClick={() => toggleAll(true)}
                  >
                    Expand All
                  </Button>
                  <Button 
                    variant="outlined"
                    size="small"
                    onClick={() => toggleAll(false)}
                  >
                    Collapse All
                  </Button>
                </Stack>
              </Box>

              {/* Status Legend */}
              <Stack direction="row" spacing={2} justifyContent="center">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: 'success.main' }} />
                  <Typography variant="body2">Available</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <ClockIcon sx={{ fontSize: 16, color: 'warning.main' }} />
                  <Typography variant="body2">Coming Soon</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <LockIcon sx={{ fontSize: 16, color: 'error.main' }} />
                  <Typography variant="body2">Not Available</Typography>
                </Box>
              </Stack>

              {/* Categories and Types */}
              <Card variant="outlined">
                {getFilteredTypes().map((category) => (
                  <React.Fragment key={category.id}>
                    <CategoryRow 
                      onClick={() => toggleCategory(category.id)}
                      sx={{ 
                        p: 2, 
                        display: 'flex', 
                        alignItems: 'center',
                        opacity: isCategoryComingSoon(category) ? 0.7 : 1,
                      }}
                    >
                      <IconButton size="small" sx={{ mr: 1 }}>
                        {expandedCategories[category.id] ? 
                          <ChevronDownIcon /> : 
                          <ChevronRightIcon />}
                      </IconButton>
                      {React.createElement(CATEGORY_ICONS[category.id], {
                        sx: { 
                          mr: 2,
                          color: isCategoryComingSoon(category) ? 'text.secondary' : '#FD9800',
                          fontSize: 24
                        }
                      })}
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          flexGrow: 1,
                          color: isCategoryComingSoon(category) ? 'text.secondary' : 'text.primary'
                        }}
                      >
                        {category.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {category.types.filter(t => t.status === 2).length} available / {category.types.length} types
                      </Typography>
                    </CategoryRow>

                    {expandedCategories[category.id] && (
                      <Box sx={{ p: 2, bgcolor: 'action.hover' }}>
                        <Grid container spacing={1}>
                          {category.types.map((type) => (
                            <Grid item xs={12} sm={6} md={4} key={type.id}>
                              <VideoTypeButton
                                onClick={() => type.status === 2 && setSelectedType(type)}
                                active={(selectedType?.id === type.id) ? 'active' : ''}
                                disabled={type.status !== 2}
                              >
                                {selectedType?.id === type.id ? (
                                  <CheckIcon sx={{ mr: 1 }} />
                                ) : type.status === 2 ? (
                                  <Box 
                                    sx={{ 
                                      width: 8, 
                                      height: 8, 
                                      borderRadius: '50%', 
                                      bgcolor: 'success.main',
                                      mr: 1.5,
                                      ml: 0.5
                                    }} 
                                  />
                                ) : null}
                                <Box sx={{ flexGrow: 1 }}>
                                  <Typography variant="body1">{type.name}</Typography>
                                  <Typography className="duration" variant="caption">
                                    {type.dur}
                                  </Typography>
                                </Box>
                                {type.status !== 2 && (
                                  type.status === 1 ? 
                                    <ClockIcon sx={{ color: 'warning.main' }} /> :
                                    <LockIcon sx={{ color: 'error.main' }} />
                                )}
                              </VideoTypeButton>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </Card>
            </Stack>
          </CardContent>
        </Card>

        <ViewFooter 
          showButton={!!selectedType}
          onComplete={handleContinue}
          buttonText={process.footer.continueText}
        />
      </Box>
    </>
  );
};

export default Glow62Processor;