import React, { useState } from "react";
import Cookies from 'js-cookie';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from "@mui/system";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { signInWithPopup, getAdditionalUserInfo, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  transition: "all 0.3s ease-in-out",
}));

const SocialButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  textTransform: "none",
  fontSize: "1rem",
  border: "1px solid var(--color-border)",
  "&:hover": {
    backgroundColor: 'var(--secondary)'
  }
}));

const Authenticate = ({ setAccountInfo, auth, showNotice, setAuthFormVisible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTab, setActiveTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });

  /**
   * Handle check and process valid authenticate data after user has been successful signed-in or logged-in
   * 
   * @param {*} data 
   * @returns 
   */
  const handleValidCredentials = async (data) => {
    const updateInfo = {}
    // Get system user token by firebase token
    if (data.userToken && data.methodOfRegistration && data.methodOfRegistration === 'GMAIL') {
      const endpoint = 'get_user_token_from_firebase_token'
      // Ensure all required fields are present
      const payload = { firebase_token: data.userToken };

      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/v1/user/${endpoint}`,
          payload
        );

        if (!response.data.status) {
          throw new Error(response.data.message || 'Process failed');
        }
        if (response.status == 200 && response.data && response.data.status_code == 200 && response.data.data.user_token && response.data.data.user_type) {
          updateInfo.userToken = response.data.data.user_token;
          updateInfo.userType = response.data.data.user_type;
          updateInfo.subscriptionType = response.data.data.subscription_type ?? '';
        }
      } catch (error) {
        console.log(`Error running:`, error);
        throw error;
      }
    } else {
      if (data.user_token && data.user_type) {
        updateInfo.userToken = data.user_token;
        updateInfo.userType = data.user_type;
        updateInfo.subscriptionType = data.subscription_type ?? '';
      } else {
        console.log(`Error running:`);
        return false;
      }
    }
    updateInfo.picture = data.picture ?? ''
    updateInfo.initials = data.initials ?? '';
    updateInfo.nickname = data.nickname ?? '';
    updateInfo.email = data.email ?? '';
    updateInfo.lastLoginAt = data.last_login_at ?? ''
    updateInfo.firstName = data.first_name ?? '';
    updateInfo.lastName = data.last_name ?? '';
    updateInfo.sessionList = [];

    setAccountInfo(prevInfo => ({
      ...prevInfo,
      ...updateInfo
    }));

    return true;
  }

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;
      const additionalUserInfo = getAdditionalUserInfo(result);
      const data = {
        "email": user.email,
        "firstName": additionalUserInfo.profile.given_name ?? '',
        "lastName": additionalUserInfo.profile.family_name ?? '',
        "picture": additionalUserInfo.profile.picture,
        "userToken": user.accessToken,
        "isNewUser": additionalUserInfo.is_new_user ?? false,
        "nickname": user.displayName ?? '',
        "methodOfRegistration": "GMAIL",
        "emailVerified": additionalUserInfo.profile.verified_email,
        "currentCredits": 0
      }
      await handleValidCredentials(data)
      if (additionalUserInfo.is_new_user)
        await registerNewUser(data);
      else
        showNotice('Hello ' + user.displayName + '! Welcome back.')
    } catch (error) {
      console.error("Google Sign In Error:", error.message);
      showNotice(error.message, 'error')
    } finally {
      setLoading(false);
    }
  };

  /**
   * Register new user in BE if new User is detected after 
   * sign-in by Google or Apple provider 
   * 
   * @param {*} data 
   */
  const registerNewUser = async (data) => {
    try {
      setLoading(true);
      let response = await axios.post(`${process.env.VITE_API_URL}/user_signup_gmail_or_apple`, data, {
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      if (response.status !== 200) {
        console.log(response.status + ' ' + response.statusText);
        showNotice(response.statusText, 'error')
      }

      const responseData = response.data;

      if (responseData.status === true && responseData.status_code === 200) {
        showNotice('You received free Credits because you registered.')
      } else if (responseData.message) {
        showNotice(responseData.message, 'error')
      } else {
        showNotice('"Unfortunately, we have encountered an error', 'error')
      }
    }
    catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleAppleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, appleProvider);
      const credential = OAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      console.log("Apple Sign In Success:", user);
    } catch (error) {
      console.error("Apple Sign In Error:", error.message);
      showNotice(error.message, 'error')
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: validateEmail(value) ? "" : "Invalid email format"
      }));
    } else if (name === "password") {
      setErrors((prev) => ({
        ...prev,
        password: value.length >= 8 ? "" : "Password must be at least 8 characters"
      }));
    }
  };

  const handleEmailLogin = async (data) => {
    const { email, password } = data;

    if (!validateEmail(email)) {
      throw new Error("Invalid email format");
    }

    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/user_login`, {
        email,
        password
      });

      if (response.data?.status_code === 200) {
        if(!response.data.email_verified) showNotice('We sent you an Email. Please verify your Email and receive additional credits.')
        console.log("Login successful:", response.data);
        return await handleValidCredentials(response.data.data)
      } else {
        throw new Error(response.data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      showNotice(error.message, 'error')
      throw error;
    } finally {
      setLoading(false);
    }

  }

  const handleEmailRegister = async (data) => {

    const guest_token = Cookies.get('token');
    if (!validateEmail(data.email)) {
      throw new Error("Invalid email format");
    }
    if (data.password.length < 8) {
      throw new Error("Password must be at least 8 characters");
    }

    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/user_signup`, {
        email: data.email,
        password: data.password,
        first_name: data.firstName,
        last_name: data.lastName,
        nickname: data.nickname || "",
        current_credits: 0,
        guest_token: guest_token
      });

      if (response.data.status_code === 200) {
        console.log("Registration successful:", response.data);

        const data = {
          "email": data.email,
          "initials": data.initials,
          "first_name": data.firstName ?? '',
          "last_name": data.lastName ?? '',
          "user_token": response.data.data.user_token,
          "nickname": data.nickname ?? '',
          "picture": '',
          "email_verified": false,
          "current_credits": 0
        }
        await handleValidCredentials(data)
      } else {
        showNotice(response.data.message, 'error')
      }
    } catch (error) {
      console.error("Error during registration:", error);
      showNotice(error.message, 'error')
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!errors.email && !errors.password) {
      setLoading(true);
      if (activeTab === 0) await handleEmailLogin(formData)
      else if (activeTab === 1) await handleEmailRegister(formData)
      setLoading(false);
    }
  };

  return (
    <StyledPaper elevation={0}>
      <IconButton
        aria-label="close"
        onClick={() => setAuthFormVisible(false)}
        sx={{ position: 'absolute', right: 16, top: 16 }}
      >
        <CloseIcon />
      </IconButton>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        aria-label="auth tabs"
        sx={{ mb: 3 }}
      >
        <Tab label="Login" id="login-tab" aria-controls="login-panel" />
        <Tab label="Register" id="register-tab" aria-controls="register-panel" />
      </Tabs>

      <form onSubmit={handleSubmit}>
        {activeTab !== 0 && (
          <>
            <TextField
              fullWidth
              type="text"
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              margin="dense"
              autoComplete="given-name"
              aria-label="First Name input"
              required
            />

            <TextField
              fullWidth
              type="text"
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              margin="dense"
              autoComplete="family-name"
              aria-label="Last Name input"
              required
            />

            <TextField
              fullWidth
              type="text"
              name="nickname"
              label="Nickname (optional)"
              value={formData.nickname}
              onChange={handleChange}
              error={!!errors.nickname}
              helperText={errors.nickname}
              margin="dense"
              autoComplete="nickname"
              aria-label="Nickname input"
            />
          </>
        )}

        <TextField
          fullWidth
          type="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          margin="dense"
          autoComplete="email"
          aria-label="Email input"
          required
        />

        <TextField
          fullWidth
          type={showPassword ? "text" : "password"}
          name="password"
          label="Password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          margin="dense"
          autoComplete="current-password"
          aria-label="Password input"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            activeTab === 0 ? "Login" : "Register"
          )}
        </Button>

        <Divider sx={{ my: 2 }}>
          <Typography color="textSecondary" variant="body2">
            OR
          </Typography>
        </Divider>

        <Box sx={{ mt: 2 }}>
          <SocialButton
            startIcon={<FcGoogle />}
            aria-label="Sign in with Google"
            onClick={handleGoogleSignIn}
            disabled={loading}
          >
            Continue with Google
          </SocialButton>
          <SocialButton
            startIcon={<BsApple />}
            sx={{ color: "#000" }}
            aria-label="Sign in with Apple"
            onClick={handleAppleSignIn}
            disabled={loading}
          >
            Continue with Apple
          </SocialButton>
        </Box>

        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ mt: 2 }}
        >
          {activeTab === 0
            ? "Don't have an account? Click Register above"
            : "Already have an account? Click Login above"}
        </Typography>
      </form>
    </StyledPaper>
  );
};

export default Authenticate;