import React, { useState, useEffect, useRef } from 'react';
import './glow_10_viewer_tamsamsom.css';
import processConfig from '../../config/processIndex.json';

const formatValue = (value) => {
  if (value >= 1000000000) {
    return `$${(value / 1000000000).toFixed(1)}B`;
  }
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  }
  return `$${value.toLocaleString()}`;
};

const TamSamSomViewer = ({ data }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [toast, setToast] = useState({ show: false, text: '', x: 0, y: 0 });
  const cardsRef = useRef(null);

  if (!data || !data.tam || !data.sam || !data.som) {
    return <div>No market analysis data available</div>;
  }

  // Update colors to match the original TSX file
  const colors = {
    som: '#FD9800',  // Primary color
    sam: '#FDB544',  // Lighter shade
    tam: '#FED38C'   // Lightest shade
  };

  // Calculate circle sizes based on market values
  const maxRadius = 180;
  const tamRadius = maxRadius;
  const samRadius = maxRadius * Math.sqrt(data.sam.value / data.tam.value);
  const somRadius = maxRadius * Math.sqrt(data.som.value / data.tam.value);

  // Calculate vertical positions for circles
  const svgHeight = maxRadius * 2;
  const centerY = svgHeight / 2;
  const baseline = centerY + maxRadius;
  const tamY = baseline - tamRadius;
  const samY = baseline - samRadius;
  const somY = baseline - somRadius;

  // Add tooltip descriptions
  const tooltips = {
    tam: "Total Addressable Market (TAM) represents the total market demand for your product or service category. It's the maximum amount of revenue a business could generate if it captured 100% of the market share.",
    sam: "Serviceable Addressable Market (SAM) is the segment of TAM that your company can realistically target with its specific business model and capabilities. It represents the portion of the market that aligns with your product offering and distribution channels.",
    som: "Serviceable Obtainable Market (SOM) is the portion of SAM that you can realistically capture in the near term. It represents your actual target market share based on your company's resources, competition, and market constraints.",
    cagr: "Compound Annual Growth Rate (CAGR) measures the annual growth rate of an investment over a specified time period. It represents the steady rate at which the market would have grown if it increased at the same rate each year.",
    industry: "The specific market sector or business domain that this analysis focuses on. This defines the scope and boundaries of the market segment.",
    description: "A detailed explanation of what this market segment encompasses and its key characteristics."
  };

  const handleMouseMove = (e) => {
    if (toast.show) {
      setToast(prev => ({
        ...prev,
        x: e.clientX + 15, // Offset from cursor
        y: e.clientY + 15
      }));
    }
  };

  const handleMouseEnter = (key, e) => {
    setActiveSection(key);
    setToast({
      show: true,
      text: tooltips[key],
      x: e.clientX + 15,
      y: e.clientY + 15
    });
  };

  const handleMouseLeave = () => {
    setActiveSection(null);
    setToast(prev => ({ ...prev, show: false }));
  };

  return (
    <div className="tam-sam-som-container" onMouseMove={handleMouseMove}>
      <div className="visualization-content">
        {/* Market Cards */}
        <div ref={cardsRef} className="market-cards">
          {[
            { key: 'tam', data: data.tam, color: colors.tam },
            { key: 'sam', data: data.sam, color: colors.sam },
            { key: 'som', data: data.som, color: colors.som }
          ].map(({ key, data: marketData, color }) => (
            <div
              key={key}
              className={`market-card ${activeSection === key ? 'active' : ''}`}
              onMouseEnter={(e) => handleMouseEnter(key, e)}
              onMouseLeave={handleMouseLeave}
              style={{ '--market-color': color }}
            >
              <div className="market-card-header">
                <h3>{key.toUpperCase()}</h3>
              </div>
              <div className="market-value">{formatValue(marketData.value)}</div>
              <div 
                className="market-industry"
                onMouseEnter={(e) => handleMouseEnter('industry', e)}
                onMouseLeave={handleMouseLeave}
              >
                {marketData.industry}
              </div>
              <div 
                className="market-description"
                onMouseEnter={(e) => handleMouseEnter('description', e)}
                onMouseLeave={handleMouseLeave}
              >
                {marketData.description}
              </div>
              <div className="market-footer">
                <div className="market-source">
                  <div>{marketData.source.name}</div>
                  <div>{marketData.source.date}</div>
                </div>
                <div 
                  className="market-cagr"
                  onMouseEnter={(e) => handleMouseEnter('cagr', e)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="cagr-label">CAGR</div>
                  <div className="cagr-value">{marketData.cagr}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Bubble Visualization */}
        <div className="bubble-visualization">
          <svg viewBox={`0 0 400 ${svgHeight}`} preserveAspectRatio="xMidYMid meet">
            {[
              { key: 'tam', y: tamY, r: tamRadius, color: colors.tam },
              { key: 'sam', y: samY, r: samRadius, color: colors.sam },
              { key: 'som', y: somY, r: somRadius, color: colors.som }
            ].map(({ key, y, r, color }) => (
              <circle
                key={key}
                cx="200"
                cy={y}
                r={r}
                fill={color}
                opacity={activeSection === key ? 1 : 0.8}
                onMouseEnter={(e) => handleMouseEnter(key, e)}
                onMouseLeave={handleMouseLeave}
                style={{ transition: 'all 0.3s ease' }}
              />
            ))}
            {/* Labels */}
            {[
              { key: 'tam', y: tamY },
              { key: 'sam', y: samY },
              { key: 'som', y: somY }
            ].map(({ key, y }) => (
              <text
                key={key}
                x="200"
                y={y}
                textAnchor="middle"
                fill="white"
                fontSize="16"
                fontWeight="bold"
                style={{ 
                  pointerEvents: 'none',
                  textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                  dominantBaseline: 'middle',
                  cursor: 'pointer'
                }}
              >
                {key.toUpperCase()}
              </text>
            ))}
          </svg>
        </div>

        {/* Cursor Toast */}
        {toast.show && (
          <div 
            className="cursor-toast"
            style={{
              position: 'fixed',
              left: toast.x,
              top: toast.y,
              transform: `translate(0, -50%)`
            }}
          >
            {toast.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default TamSamSomViewer;