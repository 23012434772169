import React, { useState, useEffect, useRef } from 'react';
import Authenticate from './auth/authenticate'; // Import the Authenticate class
import AccountMenu from './components/ui/account_menu';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged  } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, onSnapshot }  from "firebase/firestore";
import axios from 'axios';
import Cookies from 'js-cookie';
import './App.css';
import { 
  FaCheckCircle, 
  FaList, 
  FaPlusSquare, 
  FaCircle, 
  FaTools, 
  FaCopy, 
  FaDownload, 
  FaMagic, 
  FaSave, 
  FaRedo, 
  FaCheck, 
  FaPlay,
  FaTimes,
  FaEdit,
  FaTrash,
  FaBars,
  FaSun,
  FaMoon
} from 'react-icons/fa';
import Lottie from 'lottie-react';
import loadingAnimation from './animations/loading.json';
import MultiLevelStepper from './components/stepper/MultiLevelStepper';
import stepperStructure from './config/MultiLevelStepper.json';
import { 
  getProcessById, 
  getDisplayName, 
  canRunProcess, 
  getProcessResponse,
  getProcessStatus,
  getEndpoint,
  getAllProcesses,
  updateSessionDetails 
} from './utils/processUtils';
import processConfig from './config/processIndex.json';
import IdeaInputCard from './components/IdeaInputCard';
import { 
  Typography, 
  TextField, 
  Button, 
  useTheme,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  useMediaQuery
} from '@mui/material';
import ProcessCache from './utils/processCache';
import { ProcessChainManager } from './utils/processChain';
import MenuIcon from '@mui/icons-material/Menu';
import ContrastIcon from '@mui/icons-material/Contrast';
import CreditFlag from './components/ui/credit_flag'; // Import the CreditFlag component

import Glow01Processor from './components/viewers/glow_01_processor_customer';
import Glow02Processor from './components/viewers/glow_02_processor_scene';
import Glow03Processor from './components/viewers/glow_03_processor_script';
import Glow08Processor from './components/viewers/glow_08_processor_questions';
import Glow09Processor from './components/viewers/glow_09_processor_overview';
import Glow10Processor from './components/viewers/glow_10_processor_tamsamsom';
import Glow21Processor from './components/viewers/glow_21_market-comp-matrix';
import Glow11Processor from './components/viewers/glow_11_processor_prodprice';
import Glow30Processor from './components/viewers/glow_30_final_sales_pitch';
import Glow62Processor from './components/viewers/glow_62_processor_customer';
import Glow63Processor from './components/viewers/glow_63_processor_customer';

const processComponents = {
  'glow_08': Glow08Processor,
  'glow_09': Glow09Processor,
  'glow_10': Glow10Processor,
  'glow_21': Glow21Processor,
  'glow_11': Glow11Processor,
  'glow_01': Glow01Processor,
  'glow_02': Glow02Processor,
  'glow_03': Glow03Processor,
  'glow_30': Glow30Processor,
  'glow_62': Glow62Processor,
  'glow_63': Glow63Processor
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
function App() {
  const [sessionId, setSessionId] = useState('');
  const [inputText, setInputText] = useState('');
  const [sessionDetails, setSessionDetails] = useState(null);
  const [devToolOpen, setDevToolOpen] = useState(false);
  const [apiResponses, setApiResponses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [runningProcesses, setRunningProcesses] = useState({});  // Changed from object to map of session IDs
  const [isAnyProcessRunning, setIsAnyProcessRunning] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [currentProcess, setCurrentProcess] = useState('');
  const modalRef = useRef(null);
  const [showSessionOptions, setShowSessionOptions] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [sessionList, setSessionList] = useState([]);
  const [showSessionList, setShowSessionList] = useState(false);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const dropdownRef = useRef(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [isJsonView, setIsJsonView] = useState(true);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [finishedProcesses, setFinishedProcesses] = useState({});
  const theme = useTheme();
  const [completedProcesses, setCompletedProcesses] = useState({});
  const [isProcessViewCollapsed, setIsProcessViewCollapsed] = useState({});
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [highlightedButton, setHighlightedButton] = useState(null);
  const [initialAnswers, setInitialAnswers] = useState(null)
  
  const [noticeState, setNoticeState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    severity: 'success',
    duration: 2000,
    message: 'I love glowtest'
  });

  const showNotice = (message, severity = 'success') => {    
    setNoticeState({ ...noticeState,message:message, severity: severity, open: true });
  }
  const handleNoticeClose = () => {
    setNoticeState({ ...noticeState, open: false });
  };

  // State to manage subscription information
  const [subscription, setSubscription] = useState({
    credits: 0,
    subscriptionPlan: 'guest', // Default plan
  });

  // ... existing state variables ...
  const [showAuthForm, setShowAuthForm] = useState(false); // State to manage auth form visibility

  // Function to toggle the authentication form
  const toggleAuthForm = () => {
    setShowAuthForm(prev => !prev);
  };
  
  const processCache = new ProcessCache();
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };  
  
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const db = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);

  const [accountInfo, setAccountInfo] = useState(() => {
    const cookieAccountInfo = Cookies.get('accountInfo');
    console.log(cookieAccountInfo ? JSON.parse(cookieAccountInfo) : '');
    
    const parsedAccountInfo = cookieAccountInfo ? JSON.parse(cookieAccountInfo) : {
      initials: '',
      nickname: '',
      userToken: '',
      userType: '',
      subscriptionType: '',
      credit: 0,
      firstName: '',
      lastName: '',
      email: '',
      lastLoginAt: '',
      guestToken: '',
      activeSession: '',
      sessionList: []
    };
    return parsedAccountInfo;
  });

  useEffect(() => {
    // Update cookies with accountInfo whenever it changes
    Cookies.set('accountInfo', JSON.stringify(accountInfo), { expires: 30 }); // Set cookie to expire in 30 day
    
    if(accountInfo.userToken && accountInfo.userToken != '') {      
      Cookies.set('token', accountInfo.userToken, { expires: 30 }); // Set cookie to expire in 30 day
    } else {
      if(accountInfo.guestToken && accountInfo.guestToken != '') {      
        Cookies.set('token', accountInfo.guestToken, { expires: 30 }); // Set cookie to expire in 30 day
      } else {
        // Call sign_in_anonymous API to get guest token
        signInAnonymous()
      }
    }
    if(Cookies.get('token')) {
      initializeSessionSelection();
        if(db) {
          // Subscribe realtime data to get live update
          const unsubscribe = onSnapshot(doc(db, "broadcast", Cookies.get('token')), (doc) => {
            const data = doc.data()            
            if(data.current_credits)
              setSubscription({
                subscriptionPlan: data.subscriptionType ?? 'guest',
                credits: data.current_credits ?? 0,
              });

          });
      } else {
          console.log('Can not subscribe to realtime data');
      }
    }
    setShowAuthForm(false)    
  }, [accountInfo]);
  

  // Add these handlers at the top of your App component
  const handleSessionListClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSessionList(true);
  };

  const handleDevToolsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDevToolOpen(!devToolOpen);
  };

  // Add this useEffect to handle theme changes
  useEffect(() => {
    document.body.className = isDarkTheme ? 'theme-dark' : 'theme-light';
  }, [isDarkTheme]);

  const updateSessionDetails = (sessionDetails) => {
    if(!sessionDetails) {
      ProcessChainManager.clearAllCache()
      setCompletedProcesses({});
      setCurrentProcess('idea_description');
      setLoadingButtons({});
      setRunningProcesses({});
      return;
    }
    setSessionDetails(sessionDetails)
    // Update Process Chain Manager cache with each entries in sessionData
    ProcessChainManager.updateCache(sessionDetails);
    
    // Update completed processes based on status flags
    const newCompletedProcesses = {
      'idea_description': !!sessionDetails.input_prompt,
      'glow_08': sessionDetails.glow_08_status ?? false,
      'glow_09': sessionDetails.glow_09_status ?? false,
      'glow_10': sessionDetails.glow_10_status ?? false,
      'glow_11': sessionDetails.glow_11_status ?? false,
      'glow_21': sessionDetails.glow_21_status ?? false,
      'glow_30': sessionDetails.glow_30_status != undefined ? sessionDetails.glow_30_status : sessionDetails.glow_11_status,
      'glow_01': sessionDetails.glow_01_status ?? false,
      'glow_02': sessionDetails.glow_02_status ?? false,
      'glow_03': sessionDetails.glow_03_status ?? false,
      'glow_62': sessionDetails.glow_62_status ?? false,
      'glow_63': sessionDetails.glow_63_status ?? false
    };
    setCompletedProcesses(newCompletedProcesses);

    if (sessionDetails.input_prompt) {
      setInputText(sessionDetails.input_prompt);
    }
  }

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const signInAnonymous = async() => {    
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/user/sign_in_anonymous`,
      );
      if (response.status === 200 && response.data?.status_code === 200 && response.data.data) {
        const data = response.data.data;
        setAccountInfo(prevInfo => ({
          ...prevInfo,
          guestToken: data.user_token,
          subscriptionType: data.user_type ?? '',
        }));
      }
    } catch (error) {
      console.error('Error signInAnonymous:', error);
      return { status_code: 400, data: [] };
    }
  }
  const fetchSessionList = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/session/get_session_list`,
        {
          headers: { Authorization: `Bearer ${Cookies.get('token')}` },
        }
      );
      setSessionList(response.data.data.sessions || []);
      return response.data;
    } catch (error) {
      console.error('Error fetching session list:', error);
      showToast('Failed to fetch session list');
      return { status_code: 400, data: [] };
    }
  };

  const createSession = async () => {
    try {
      setIsCreating(true);
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/session/create_session`,
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      const newSessionId = response.data.data.session_id;
      
      // Clear the cache before setting new session
      ProcessChainManager.clearCache();
      
      setSessionId(newSessionId);
      Cookies.set('session_id', newSessionId);
      addApiResponse(response.data);
      
      // Reset all states for new session
      updateSessionDetails(null);
      
      await getSessionDetails(newSessionId);      
      await fetchSessionList();
      showToast('New session created successfully', 'success');
      return newSessionId;
    } catch (error) {
      console.error('Error creating session:', error);
      addApiResponse({ status: false, message: 'Error creating session' });
      showToast('Failed to create new session', 'error');
      throw error;
    } finally {
      setIsCreating(false);
    }
  };

  // Add a ref to track initialization
  const isInitialized = useRef(false);

  // useEffect(() => {
  //   initializeSessionSelection();
  // }, []);

  const initializeSessionSelection = async () => {
    try {
      const listResponse = await fetchSessionList();
      
      const hasValidSessions = listResponse.data?.sessions && 
                            Array.isArray(listResponse.data.sessions) && 
                            listResponse.data.sessions.length > 0;
      // Create new session if:
      // 1. Response has error (status_code 400)
      // 2. Response data is empty or invalid
      // 3. No sessions in the list
      if (listResponse.status_code === 400 || !hasValidSessions) {
        await createSession();
        return;
      }

      const savedSessionId = Cookies.get('session_id');
      const sessionExists = listResponse.data.sessions.some(
        session => session.session_id === savedSessionId
      );
      
      const targetSessionId = (savedSessionId && sessionExists) ? 
        savedSessionId : 
        listResponse.data.sessions[0].session_id;

      setSessionId(targetSessionId);
      Cookies.set('session_id', targetSessionId);
      
      // Use existing getSessionDetails function
      await getSessionDetails(targetSessionId);
    } catch (error) {
      console.error('Initialization error:', error);
    }
  };

  const addApiResponse = (response) => {
    const timestamp = new Date().toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    setApiResponses((prev) => [...prev, { ...response, showDetails: false, timestamp }]);
  };

  const toggleResponseDetails = (index) => {
    setApiResponses((prev) =>
      prev.map((resp, i) =>
        i === index ? { ...resp, showDetails: !resp.showDetails } : resp
      )
    );
  };

  const  getSessionDetails = async (id) => {
    // Return cached data if available and less than 5 seconds old    
    const cached = processCache.get(id);
    if (cached ) {
      updateSessionDetails(cached);
      return cached;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/get_session_details`,
        { session_id: id },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      if (response.data.status) {
        const sessionData = response.data.data.session_details;
        // Cache the response
        processCache.set(id, sessionData)
        updateSessionDetails(sessionData);
        return sessionData;
      }
      throw new Error(response.data.message || 'Failed to fetch session details');
    } catch (error) {
      console.error('Error fetching session details:', error);
      throw error;
    }
  };

  const formatJSON = (json) => {
    try {
      return JSON.stringify(JSON.parse(json), null, 2);
    } catch {
      return json;
    }
  };

  const openModal = (content) => {
    setModalContent(formatJSON(content));
    setModalOpen(true);
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.classList.add('open');
      }
    }, 10);
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.classList.remove('open');
    }
    setTimeout(() => {
      setModalOpen(false);
    }, 300); // Match this with the transition duration
  };

  const showToast = (message, type = 'info') => {
    setToast({ show: true, message, type });
    showNotice(message, type)
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      showToast('Copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
      showToast('Failed to copy to clipboard');
    });
  };

  const downloadContent = (content, process) => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(JSON.parse(content), null, 2)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    const sessionName = sessionDetails?.session_name || 'session';
    element.download = `${sessionName}_${process}_response.json`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const viewResponse = async (process) => {
    if (!sessionId) {
      console.error('No session ID available');
      addApiResponse({ status: false, message: 'No session ID available' });
      return;
    }

    const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), ms));

    try {
      const response = await Promise.race([
        axios.post(
          `${API_BASE_URL}/api/v1/session/get_session_details`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
        ),
        timeout(5000) // 5 second timeout
      ]);
      
      const sessionDetails = response.data.data.session_details;
      let content = '';
      
      switch(process) {
        case 'glow_01':
          content = sessionDetails.glow_01_data ? JSON.stringify(sessionDetails.glow_01_data.glow_01_response) : 'No data available';
          break;
        case 'glow_02':
          content = sessionDetails.glow_02_data ? JSON.stringify(sessionDetails.glow_02_data.glow_02_response) : 'No data available';
          break;
        case 'glow_03':
          content = sessionDetails.glow_03_data ? JSON.stringify(sessionDetails.glow_03_data.glow_03_response) : 'No data available';
          break;
        case 'glow_09':
          content = sessionDetails.glow_09_data ? JSON.stringify(sessionDetails.glow_09_data.glow_09_response) : 'No data available';
          break;
        case 'glow_10':
          content = sessionDetails.glow_10_data ? JSON.stringify(sessionDetails.glow_10_data.glow_10_response) : 'No data available';
          break;
        case 'glow_21':
          content = sessionDetails.glow_21_data ? JSON.stringify(sessionDetails.glow_21_data.glow_21_response) : 'No data available';
          break;
        case 'glow_11':
          content = sessionDetails.glow_11_data ? JSON.stringify(sessionDetails.glow_11_data.glow_11_response) : 'No data available';
          break;
        case 'glow_30':
          content = sessionDetails.glow_30_data ? JSON.stringify(sessionDetails.glow_30_data.glow_30_response) : 'No data available';
          break;
        case 'glow_08':
          content = sessionDetails.glow_08_data ? JSON.stringify(sessionDetails.glow_08_data.glow_08_response) : 'No data available';
          break;
        case 'glow_63':
          content = sessionDetails.glow_63_data ? JSON.stringify(sessionDetails.glow_63_data.glow_63_response) : 'No data available';
          break;
        default:
          content = 'Invalid process';
      }
      
      setCurrentProcess(process);
      openModal(content);
    } catch (error) {
      if (error.message === 'Request timed out') {
        console.error('Request timed out. The server might be busy.');
        addApiResponse({ status: false, message: 'Request timed out. Please try again later.' });
      } else {
        console.error('Error getting session details:', error);
        addApiResponse({ status: false, message: 'Error getting session details' });
      }
    }
  };

  const runProcess = async (processId) => {
    if (!sessionId) {
      showToast('No session selected', 'error');
      return;
    }

    // Update running state for specific session and process
    setRunningProcesses(prev => ({
      ...prev,
      [sessionId]: {
        ...(prev[sessionId] || {}),
        [processId]: true
      }
    }));

    try {
      const payload = { 
        session_id: sessionId,
      };

      if (processId === 'glow_01') {
        payload.input_prompt = inputText;
        const targetGroupCount = document.querySelector('.target-group-count-input')?.value || '3';
        payload.number_of_target_group = parseInt(targetGroupCount, 10);
      }

      const response = await axios.post(
        `${API_BASE_URL}${getEndpoint(processId, 'run')}`,
        payload,
        { 
          headers: { 
            Authorization: `Bearer ${Cookies.get('token')}` 
          } 
        }
      );
      
      addApiResponse(response.data);
      await getSessionDetails(sessionId);
      showToast(`${getDisplayName(processId)} completed successfully`, 'success');
      setFinishedProcesses(prev => ({
        ...prev,
        [processId]: true
      }));
    } catch (error) {
      console.error(`Error running ${processId}:`, error);
      addApiResponse({ status: false, message: `Error running ${processId}` });
      showToast(`Failed to run ${getDisplayName(processId)}`, 'error');
      setFinishedProcesses(prev => ({
        ...prev,
        [processId]: false
      }));
    } finally {
      setRunningProcesses(prev => ({
        ...prev,
        [sessionId]: {
          ...(prev[sessionId] || {}),
          [processId]: false
        }
      }));
    }
  };

  // Process function that checks if process can be run
  const processFunction = async (processId) => {
    try {
      // For new idea submissions, create new session first
      if (processId === 'idea_description' || processId === 'glow_09') {
        // Clear the cache before creating new session
        ProcessChainManager.clearCache();
        
        // Create new session
        const newSessionId = await createSession();
        
        // Update session ID and continue with process
        setSessionId(newSessionId);
        Cookies.set('session_id', newSessionId);
      }

      if (!canRunProcess(processId, sessionDetails)) {
        showToast('Process cannot be run at this time', 'error');
        return;
      }

      try {
        console.log('Starting process:', processId);
        setLoadingButtons(prev => ({ ...prev, [processId]: true }));
        
        const endpoint = getEndpoint(processId);
        if (!endpoint) {
          throw new Error(`No endpoint configured for process ${processId}`);
        }
        console.log('Using endpoint:', endpoint);
        
        const payload = {
          session_id: sessionId,
          input_prompt: inputText
        };
        console.log('Sending payload:', payload);

        const response = await axios.post(
          `${API_BASE_URL}/api/v1/${endpoint}`,
          payload,
          {
            headers: { Authorization: `Bearer ${Cookies.get('token')}` }
          }
        );

        console.log('Response:', response.data);

        if (response.data.status) {
          setFinishedProcesses(prev => ({ ...prev, [processId]: true }));
          await getSessionDetails(sessionId);
          showToast(`${getDisplayName(processId)} completed successfully`, 'success');
        } else {
          showToast(`${getDisplayName(processId)} failed`, 'error');
        }
      } catch (error) {
        console.error(`Error running ${processId}:`, error);
        showToast(`Error running ${getDisplayName(processId)}`, 'error');
      } finally {
        setLoadingButtons(prev => ({ ...prev, [processId]: false }));
      }
    } catch (error) {
      console.error(`Error running ${processId}:`, error);
      showToast(`Error running ${getDisplayName(processId)}`, 'error');
    } finally {
      setLoadingButtons(prev => ({ ...prev, [processId]: false }));
    }
  };

  const startRenameSession = (id, currentName) => {
    setEditingSessionId(id);
    setNewSessionName(currentName);
  };

  const cancelRenameSession = () => {
    setEditingSessionId(null);
    setNewSessionName('');
  };

  const confirmRenameSession = async (id) => {
    if (!newSessionName || !newSessionName.trim()) {
      showToast('Please enter a new name for the session');
      return;
    }
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/rename_session`,
        { session_id: id, new_name: newSessionName },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );
      addApiResponse(response.data);
      fetchSessionList();
      setEditingSessionId(null);
      setNewSessionName('');
      showToast('Session renamed successfully');
    } catch (error) {
      console.error('Error renaming session:', error);
      addApiResponse({ status: false, message: 'Error renaming session' });
      showToast('Failed to rename session');
    }
  };

  const initiateDeleteSession = (sessionId, sessionName) => {
    setDeleteConfirmation({ id: sessionId, name: sessionName });
  };

  const cancelDeleteSession = () => {
    setDeleteConfirmation(null);
  };

  const deleteSession = async (sessionId) => {
    try {
      setIsDeleting(true);
      setDeleteConfirmation(null); // Close the confirmation modal

      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/delete_session`,
        { session_id: sessionId },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      // Fetch updated session list after deletion
      const updatedListResponse = await fetchSessionList();
      
      const hasRemainingSessions = updatedListResponse.data?.sessions && 
                                  Array.isArray(updatedListResponse.data.sessions) && 
                                  updatedListResponse.data.sessions.length > 0;

      if (!hasRemainingSessions) {
        await createSession();
      } else {
        const firstSessionId = updatedListResponse.data.sessions[0].session_id;
        setSessionId(firstSessionId);
        Cookies.set('session_id', firstSessionId);
        await getSessionDetails(firstSessionId);
      }

      showToast('Session deleted successfully');
    } catch (error) {
      console.error('Error deleting session:', error);
      showToast('Failed to delete session');
    } finally {
      setIsDeleting(false);
    }
  };

  const confirmDeleteSession = async () => {
    if (deleteConfirmation) {
      await deleteSession(deleteConfirmation.id);
    }
  };

  const changeCurrentSession = (id) => {
    setSessionId(id);
    Cookies.set('session_id', id);
    getSessionDetails(id);
    setShowSessionList(false);
  };

  const openSessionList = () => {
    setShowSessionList(true);
  };

  const selectSession = async (id) => {
    setSessionId(id);
    Cookies.set('session_id', id);
    setShowSessionList(false);
    await getSessionDetails(id);
    showToast('Session selected successfully');
  };

  const renameSession = async (sessionId, newName) => {
    try {
      setIsRenaming(true);
      const response = await axios.post(`${API_BASE_URL}/api/v1/session/rename_session`, {
        session_id: sessionId,
        new_name: newName
      }, {
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        }
      });
      
      await fetchSessionList();
      setEditingSessionId(null);
      setNewSessionName('');
      showToast('Session renamed successfully', 'success');
    } catch (error) {
      console.error('Error renaming session:', error);
      showToast('Failed to rename session', 'error');
    } finally {
      setIsRenaming(false);
    }
  };

  // Helper function to check if a specific session has any running process
  const isSessionProcessRunning = (sid) => {
    console.log('Checking if session is running:', sid);
    console.log('Running processes:', runningProcesses);
    return runningProcesses[sid] && Object.values(runningProcesses[sid]).some(isRunning => isRunning);
  };

  // Helper function to check if a specific process is running for a session
  const isProcessRunning = (checkSessionId, processId) => {
    return Boolean(runningProcesses[checkSessionId]?.[processId]);
  };

  const formatPrettyJson = () => {
    try {
      const parsed = JSON.parse(modalContent);
      setModalContent(JSON.stringify(parsed, null, 2));
    } catch (error) {
      showToast('Invalid JSON format', 'error');
    }
  };

  const saveContent = async () => {
    try {
      // Validate JSON
      const parsedContent = JSON.parse(modalContent);
      if (Object.keys(parsedContent).length === 0) {
        showToast('Cannot save empty JSON object', 'error');
        return;
      }

      setIsSaving(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/session/update_session_response`,
        {
          process: currentProcess,
          session_id: sessionId,
          updated_response: parsedContent
        },
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
      );

      if (response.data.status) {
        // After successful save, fetch updated session details
        const sessionResponse = await axios.post(
          `${API_BASE_URL}/api/v1/session/get_session_details`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
        );

        if (sessionResponse.data.status) {
          setSessionDetails(sessionResponse.data.data);
          showToast('Changes saved successfully', 'success');
          setHasUnsavedChanges(false);
          setShowSaveConfirmation(false);
        } else {
          showToast('Changes saved but failed to refresh data', 'warning');
        }
      } else {
        showToast(response.data.message || 'Failed to save changes', 'error');
      }
    } catch (error) {
      if (error.name === 'SyntaxError') {
        showToast('Invalid JSON format', 'error');
      } else {
        console.error('Error saving content:', error);
        showToast(error.response?.data?.message || 'Failed to save changes', 'error');
      }
    } finally {
      setIsSaving(false);
    }
  };

  // Update modal content handler
  const handleModalContentChange = (e) => {
    setModalContent(e.target.value);
    setHasUnsavedChanges(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    if (hasUnsavedChanges) {
      setShowDiscardConfirmation(true);
    } else {
      closeModal();
    }
  };

  // Add confirmation handlers
  const handleSaveConfirm = () => {
    saveContent();
  };

  const handleDiscardConfirm = () => {
    setShowDiscardConfirmation(false);
    setHasUnsavedChanges(false);
    closeModal();
  };

  // Add this function to get the process display name
  const getProcessDisplayName = (processName) => {
    return getDisplayName(processName);
  };

  // Add this function to check if the content is valid JSON
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  // First, update the process columns rendering
  const renderProcessColumns = () => {
    const processes = getAllProcesses();
    const categories = {
      business: processes.filter(p => p.category === 'business'),
      text: processes.filter(p => p.category === 'text'),
      pictures: processes.filter(p => p.category === 'pictures')
    };

    return (
      <div className="dashboard-content">
        <div className="process-controls">
          {/* Business Category */}
          <div className="process-category">
            <h2 className="category-title">{processConfig.uiStrings.categoryTitles.business}</h2>
            <div className="category-content">
              {categories.business.map(process => (
                <div key={process.id} className="process-column">
                  <div className="process-header">
                    <button 
                      className={`collapse-button ${isProcessViewCollapsed[process.id] ? 'collapsed' : ''}`}
                      onClick={() => setIsProcessViewCollapsed(prev => ({
                        ...prev,
                        [process.id]: !prev[process.id]
                      }))}
                    >
                      <span>{isProcessViewCollapsed[process.id] ? '►' : '▼'}</span>
                    </button>
                    <h2>{process.displayName}</h2>
                  </div>
                  
                  <div className="process-actions">
                    {process.id === 'glow_01' && (
                      <div className="target-group-input">
                        <label htmlFor="targetGroupCount">{processConfig.uiStrings.targetGroupsLabel}</label>
                        <input
                          id="targetGroupCount"
                          type="number"
                          min="2"
                          max="20"
                          defaultValue="2"
                          className="target-group-count-input"
                        />
                      </div>
                    )}
                    
                    {/* Add your process buttons here */}
                  </div>

                  {!isProcessViewCollapsed[process.id] && 
                   getProcessStatus(process.id, sessionDetails) && (
                    <div className="process-view-container">
                      {process.id === 'glow_01' ? (
                        <IdeaInputCard 
                          inputText={inputText}
                          setInputText={setInputText}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasUnsavedChanges={setHasUnsavedChanges}
                          onRun={handleRunMainProcess}
                          config={processConfig.initialProcess}
                          isDarkTheme={isDarkTheme}
                        />
                      ) : process.id === 'glow_02' ? (
                        <Glow02Processor
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                          onUpdate={async (updatedData) => {
                            await updateSessionDetails(sessionId, updatedData);
                            await getSessionDetails(sessionId);
                          }}
                        />
                      ) : process.id === 'glow_08' ? (
                        <Glow08Processor 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                        />
                      ) : process.id === 'glow_09' ? (
                        <Glow09Processor 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                        />
                      ) : process.id === 'glow_10' ? (
                        <Glow10Processor 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                        />
                      ) : process.id === 'glow_21' ? (
                        <Glow21Processor 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                          sessionId={sessionId}
                          onContinue={() => handleProcessComplete(process.id)}
                        />
                      ) : process.id === 'glow_11' ? (
                        <Glow11Processor 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                        />
                      ) : process.id === 'glow_30' ? (
                        <Glow30Processor 
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                        />
                      ) : process.id === 'glow_62' ? (
                        <Glow62Processor
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                          onComplete={() => handleProcessComplete(process.id)}
                        />
                      ) : process.id === 'glow_63' ? (
                        <Glow63Processor
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                          onComplete={() => handleProcessComplete(process.id)}
                        />
                      ) : (
                        <Glow03Processor
                          data={getProcessResponse(process.id, sessionDetails) || {}}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update the renderProcessButtons section in your main render area
  const renderProcessButtons = () => {
    if (!sessionId || !sessionDetails) return null;

    return (
      <div className="process-buttons">
        {getAllProcesses().map(process => (
          <div key={process.id} className="process-button-container">
            {renderProcessButton(process.id)}
          </div>
        ))}
      </div>
    );
  };

  // Update the process button rendering
  const renderProcessButton = (processId) => {
    const isLoading = loadingButtons[processId];
    const isOtherProcessRunning = isAnyProcessRunning && !isLoading;

    return (
      <button
        onClick={() => processFunction(processId)}
        disabled={isLoading || isOtherProcessRunning}
        className={`process-button ${isLoading ? 'loading' : ''} ${isOtherProcessRunning ? 'disabled' : ''}`}
      >
        {isLoading ? (
          <>
            <Lottie 
              animationData={loadingAnimation}
              loop={true}
              style={{ width: 20, height: 20, display: 'inline-block', marginRight: 5 }}
            />
            Running {getDisplayName(processId)}...
          </>
        ) : (
          getDisplayName(processId)
        )}
      </button>
    );
  };

  // Add this new function to handle the chain execution
  const runAllTextProcesses = async () => {
    try {
      // 1. Reset all states
      setFinishedProcesses({});
      setLoadingButtons(prev => ({ 
        ...prev, 
        'prepare-all': true  // Main button loading state
      }));
      setIsAnyProcessRunning(true);

      // 2. Get the text chain from config
      const textChain = processConfig.processChains.textChain;

      // 3. Run processes sequentially
      for (const processId of textChain) {
        // Set current process to loading
        setLoadingButtons(prev => ({
          ...prev,
          [processId]: true
        }));

        // Run the process
        await runProcess(processId);

        // Mark process as finished
        setFinishedProcesses(prev => ({
          ...prev,
          [processId]: true
        }));

        // Clear loading state for this process
        setLoadingButtons(prev => ({
          ...prev,
          [processId]: false
        }));

        // Small delay between processes
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      // 4. Chain completed successfully
      showToast(processConfig.uiStrings.toasts.processesCompleted, 'success');

    } catch (error) {
      console.error('Error in text chain:', error);
      showToast(processConfig.uiStrings.toasts.processesFailed, 'error');
    } finally {
      // 5. Reset states
      setLoadingButtons(prev => ({ 
        ...prev, 
        'prepare-all': false 
      }));
      setIsAnyProcessRunning(false);
    }
  };

  const handleRerun = (processId, e) => {
    e.stopPropagation(); // Prevent button click
    setFinishedProcesses(prev => ({
      ...prev,
      [processId]: false
    }));
    runProcess(processId);
  };

  const renderUserAvatar = () => {
    // Check if accountInfo.userToken
    if(accountInfo.userToken !== '') {
      return <AccountMenu accountInfo={accountInfo} setAccountInfo={setAccountInfo} auth={auth} />
    }
    {/* Render the authentication form conditionally */}
    return (
    <IconButton onClick={toggleAuthForm} disabled={isAnyProcessRunning}>
        <Typography variant="button">Login</Typography> {/* Login button */}
    </IconButton>
    )
  };

  // Add this function to handle rerun of all processes
  const handleRerunAll = (e) => {
    e.stopPropagation();
    setFinishedProcesses({});
    runAllTextProcesses();
  };

  // Add this function to handle feature requests
  const handleFeatureRequest = (featureId) => {
    showToast(`Feature "${featureId}" has been requested`, 'success');
    // You could also send this to your backend
  };

  useEffect(() => {
    const handleResize = () => {
      // Remove these since we're not using icicle anymore
      // setWidth(window.innerWidth * 0.8);
      // setHeight(window.innerHeight * 0.6);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Move renderProcessContent inside App
  const renderProcessContent = (processId) => {
    const currentChainIndex = ProcessChainManager.processOrder.indexOf(processId);
    const prevProcess = ProcessChainManager.processOrder[currentChainIndex - 1];
    const nextProcess = ProcessChainManager.processOrder[currentChainIndex + 1];
    const prevData = ProcessChainManager.getCachedResponseData(prevProcess) || {};
    const data = ProcessChainManager.getCachedResponseData(processId) || {};
    const ProcessComponent = processComponents[processId];
    if (!ProcessComponent) return null;

    return (
      <ProcessComponent
        prevData={prevData}
        data={data}
        onComplete={(updateData = {}) => {    
          if (updateData?.processId && updateData?.data) { 
            const newDetail = sessionDetails;
            newDetail[`${updateData.processId}_status`] = true;
            newDetail[`${updateData.processId}_data`] = updateData.data;            
            updateSessionDetails(newDetail);
            processCache.set(sessionId, newDetail)
          }
          handleProcessTransition(processId, nextProcess)
        }}
        sessionId={sessionId}        
      />
    );
  };

  // Move handleProcessSelect inside App
  const handleProcessSelect = async (processId) => {
    // Simply set the current process without any checks
    setCurrentProcess(processId);
  };

  // Add this function inside the App component
  const handleSaveChanges = async () => {
    try {
      setIsSaving(true);
      if (sessionId) {
        await updateSessionDetails(sessionId, { idea_description: inputText });
        setHasUnsavedChanges(false);
        setToast({
          show: true,
          message: processConfig.uiStrings.updateMessages.saved,
          type: 'success'
        });
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setToast({
        show: true,
        message: processConfig.uiStrings.updateMessages.error,
        type: 'error'
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Add this helper function to compare input text with session data
  const hasInputChanged = (currentInput) => {
    const savedInput = sessionDetails?.idea_description_data?.idea_description_response;
    return !savedInput || currentInput.trim() !== savedInput.trim();
  };

  // Handle session initialization
  const initializeSession = async (isNew) => {
    if (!isNew) return sessionId;
    
    const newSessionId = await createSession();
    setSessionId(newSessionId);
    Cookies.set('session_id', newSessionId);
    ProcessChainManager.clearCache();
    return newSessionId;
  };

  // Run the Glow09 market overview process
  const runGlow09MarketOverview = async (targetSessionId, input) => {
    const response = await ProcessChainManager.runProcess('glow_09', targetSessionId, {
      input_text: input,
      input_prompt: input
    });
    
    if (!response.status) {
      throw new Error(response.message || 'Process failed');
    }
    return response;
  };

  // Generic function to update session state for any process
  const updateProcessSessionState = async (targetSessionId, processId) => {
    const updatedSessionDetails = await getSessionDetails(targetSessionId);
    const sessionData = updatedSessionDetails.data.session_details;
    
    setSessionDetails(sessionData);
    
    // Update completed processes based on process ID
    const processStatusKey = `${processId}_status`;
    const processDataKey = `${processId}_data`;
    
    setCompletedProcesses(prev => ({
      ...prev,
      [processId]: sessionData[processStatusKey]
    }));
    
    if (!sessionData[processDataKey]) {
      throw new Error(`Failed to load ${processId} data after process completion`);
    }
    
    setCurrentProcess(processId);
    return sessionData;
  };

  // Update handleRunMainProcess to use hasInputChanged instead
  const handleRunMainProcess = async () => {
    if (isAnyProcessRunning) return;
    
    try {
      setIsAnyProcessRunning(true);
      
      const isNew = hasInputChanged(inputText);
      
      // Initialize session (new or existing)
      const targetSessionId = await initializeSession(isNew);
      
      // Instead of making an API call, just update the UI state
      setCurrentProcess('glow_08');
      setCompletedProcesses(prev => ({
        ...prev,
        'idea_description': true,
        'glow_08': true
      }));
      
      // No need to call updateProcessSessionState or ProcessChainManager
      showToast('Moving to Initial Questions', 'success');
      
    } catch (error) {
      console.error('Error transitioning to questions:', error);
      showToast(error.message || 'Failed to transition', 'error');
    } finally {
      setIsAnyProcessRunning(false);
    }
  };

  const  handleProcessTransition = async (currentProcessId, nextProcessId ) => {    
    if (!nextProcessId) return;
    try {
      setIsAnyProcessRunning(true);

      // Check if next process is preloaded
      let response = ProcessChainManager.getCachedResponse(nextProcessId);
      
      if (!response) {
        // Get the current process response to use as input for the next process
        const currentProcessResponse = ProcessChainManager.getCachedResponse(currentProcessId);
        
        // Create a complete payload
        const payload = {
          session_id: sessionId,
          process_id: nextProcessId,
          input_text: inputText,
          input_prompt: inputText,
          previous_process: currentProcessId,
          previous_response: currentProcessResponse,
          options: {
            max_length: processConfig.processes.find(p => p.id === nextProcessId)?.validations?.maxLength?.description || 2000
          }
        };

        // Run the next process with the complete payload
        response = await ProcessChainManager.runProcess(nextProcessId, sessionId, payload);
      }

      if (response.status) {
        // Rest of the function remains the same
        if(!response.isCached) {
          const newDetail = sessionDetails
          newDetail[`${nextProcessId}_status`] = true;
          newDetail[`${nextProcessId}_data`] = response.data;        
          updateSessionDetails(newDetail)
          processCache.set(sessionId, newDetail)
        }
        setCurrentProcess(nextProcessId);

        // Preload next process
        const subsequentProcess = ProcessChainManager.processOrder[
          ProcessChainManager.processOrder.indexOf(nextProcessId) + 1
        ];
        
        if (subsequentProcess) {
          ProcessChainManager.preloadNextProcess(nextProcessId, sessionId, {
            previous_response: response,
            input_text: inputText,
            input_prompt: inputText
          });
        }

        setToast({
          show: true,
          message: processConfig.processes.find(p => p.id === nextProcessId)?.completedText,
          type: 'success'
        });
      }

    } catch (error) {
      console.error('Error in process transition:', error);
      setToast({
        show: true,
        message: error.response?.data?.message || 'Error transitioning to next process',
        type: 'error'
      });
    } finally {
      setIsAnyProcessRunning(false);
    }
  };

  const handleButtonHighlight = (highlightInfo) => {
    if (highlightInfo) {
      const button = document.getElementById(highlightInfo.buttonId);
      if (button) {
        // Add highlight class for styled-components
        button.classList.add('highlight');
        
        // Add direct styles
        button.style.animation = `pulse ${highlightInfo.highlight.duration}ms infinite`;
        button.style.backgroundColor = highlightInfo.highlight.color;
        button.style.boxShadow = '0 0 10px rgba(253, 152, 0, 0.5)';
      }
    } else {
      // Clear all button highlights
      ['start-analysis-button', ...[...document.querySelectorAll('[id^="glow-"]')].map(el => el.id)]
        .forEach(id => {
          const button = document.getElementById(id);
          if (button) {
            button.classList.remove('highlight');
            button.style.animation = '';
            button.style.backgroundColor = '';
            button.style.boxShadow = '';
          }
        });
    }
  };

  const handleSessionSelect = async (selectedSessionId) => {
    try {
      setSessionId(selectedSessionId);
      Cookies.set('session_id', selectedSessionId);
      await getSessionDetails(selectedSessionId);
      setShowSessionList(false);
      setCurrentProcess('idea_description');
    } catch (error) {
      console.error('Error selecting session:', error);
      showToast('Failed to load session', 'error');
    }
  };

  const handleDeleteSession = (sessionId) => {
    initiateDeleteSession(sessionId);
  };

  const handleRenameSession = (sessionId) => {
    setEditingSessionId(sessionId);
  };

  // Add this function near other handlers in App.js
  const handleProcessComplete = async (processId) => {
    try {
      // Update completed processes state
      setCompletedProcesses(prev => ({
        ...prev,
        [processId]: true
      }));

      // Get the next process from processConfig
      const currentProcess = getProcessById(processId);
      const nextProcessId = currentProcess?.navigation?.nextStep;

      if (nextProcessId) {
        setCurrentProcess(nextProcessId);
      }
    } catch (error) {
      console.error('Error completing process:', error);
      showToast('Error completing process', 'error');
    }
  };

  return (
    <div className="App" onClick={null}>
      <AppBar 
        position="fixed" 
        sx={{ 
          zIndex: 1200,
          bgcolor: isDarkTheme ? '#1C1C1E' : '#FFFFFF',
          borderBottom: `1px solid var(--color-border)`,
          boxShadow: 'none',
          height: '60px',
          color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
          '& .MuiIconButton-root': { 
            color: isDarkTheme ? '#FFFFFF' : '#1C1C1E'
          }
        }}
      >
        <Toolbar sx={{ height: '60px', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isMobile && (
              <IconButton 
                edge="start" 
                onClick={() => setMobileOpen(!mobileOpen)}
                sx={{ display: { sm: 'none' } }}
              >
                <FaBars />
              </IconButton>
            )}
            <IconButton 
              onClick={handleSessionListClick}
              sx={{ display: { xs: 'none', sm: 'block' } }}
              disabled={isAnyProcessRunning || isCreating}
            >
              <FaList /> 
            </IconButton>
            <IconButton onClick={handleDevToolsClick} disabled={isAnyProcessRunning}>
              <FaTools />
            </IconButton>
            <IconButton 
              onClick={toggleTheme} 
              disabled={isAnyProcessRunning}
              sx={{ 
                color: isDarkTheme ? '#FFFFFF' : '#1C1C1E',
                '&:hover': {
                  color: 'var(--color-primary)'
                }
              }}
            >
              <ContrastIcon />
            </IconButton>
          </Box>
          
          {/* Logo - centered on mobile */}
          <Box sx={{ 
            position: 'absolute', 
            left: '50%', 
            transform: 'translateX(-50%)',
            width: { xs: '120px', sm: 'auto' }  // Smaller on mobile
          }}>
            <img 
              src={isDarkTheme ? '/images/logo-dark-desktop.png' : '/images/logo-light-desktop.png'} 
              alt="Logo" 
              style={{ 
                height: isMobile ? '47x' : '63px',  // Increased by 30% from 30px and 40px
                width: 'auto'
              }}
            />
          </Box>
          { renderUserAvatar() }
        </Toolbar>
      </AppBar>

      <CreditFlag 
        credits={subscription.credits} 
        subscriptionPlan={subscription.subscriptionPlan} 
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={noticeState.open}
        onClose={handleNoticeClose}
        TransitionComponent={'SlideTransition'}
        key={noticeState.vertical + noticeState.horizontal}
        autoHideDuration={noticeState.duration}
      >
        <Alert
          onClose={handleNoticeClose}
          severity={noticeState.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          { noticeState.message ?? ''}
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', pt: '60px' }}>
        {/* Desktop Stepper */}
        <Box sx={{ 
          display: { xs: 'none', sm: 'block' },
          width: '280px',
          flexShrink: 0,
          position: 'fixed',
          left: 0,
          top: '60px',
          height: 'calc(100vh - 60px)',
          overflowY: 'auto',
          borderRight: `1px solid ${theme.palette.divider}`
        }}>
          <MultiLevelStepper 
            onProcessSelect={handleProcessSelect}
            activeProcessId={currentProcess}
            completedProcesses={completedProcesses}
            isProcessRunning={isAnyProcessRunning}
            isDarkTheme={isDarkTheme}
            onButtonHighlight={handleButtonHighlight}
            sessionId={sessionId}
          />
        </Box>

        {/* Main Content */}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1,
            width: { xs: '100%', sm: `calc(100% - 280px)` },
            ml: { xs: 0, sm: '280px' },
            p: { xs: 2, sm: 3 },  // Adjusted padding
            height: 'calc(100vh - 60px)',
            overflow: 'auto',
            '& .process-view-container': {  // Add this
              width: '100%',
              maxWidth: '100%',
              '& .MuiFormControl-root': {
                width: '100%'
              }
            }
          }}
        >
          {(!currentProcess || currentProcess === 'idea_description') ? (
            <IdeaInputCard 
              inputText={inputText}
              setInputText={setInputText}
              hasUnsavedChanges={hasUnsavedChanges}
              setHasUnsavedChanges={setHasUnsavedChanges}
              onRun={handleRunMainProcess}
              config={processConfig.initialProcess}
              isDarkTheme={isDarkTheme}
            />
          ) : (
            renderProcessContent(currentProcess)
          )}
        </Box>
      </Box>

      {/* Session List Dialog */}
      {showSessionList && (
        <Dialog 
          open={showSessionList} 
          onClose={() => setShowSessionList(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Sessions
            <IconButton
              onClick={() => setShowSessionList(false)}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <FaTimes />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <List>
              {sessionList.map((session) => (
                <ListItem
                  key={session.session_id}
                  selected={session.session_id === sessionId}
                  onClick={() => handleSessionSelect(session.session_id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <ListItemText 
                    primary={session.session_name || `Session ${session.session_id}`} 
                    secondary={new Date(session.created_at).toLocaleString()}
                  />
                  <Box>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingSessionId(session.session_id);
                      }}
                      disabled={isRenaming}
                    >
                      <FaEdit />
                    </IconButton>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        initiateDeleteSession(session.session_id, session.session_name);
                      }}
                      disabled={isDeleting}
                    >
                      <FaTrash />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      )}

      {/* Dev Tools Panel */}
      {devToolOpen && (
        <Dialog 
          open={devToolOpen} 
          onClose={() => setDevToolOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            Developer Tools
            <IconButton
              onClick={() => setDevToolOpen(false)}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <FaTimes />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <h3>Session Details</h3>
              <pre style={{ overflow: 'auto' }}>
                {JSON.stringify(sessionDetails, null, 2)}
              </pre>
            </Box>
            <Box>
              <h3>API Responses</h3>
              <pre style={{ overflow: 'auto' }}>
                {JSON.stringify(apiResponses, null, 2)}
              </pre>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      
      {/* Pass firebaseApp to AuthForms */}
      {showAuthForm && 
        <Dialog 
          open={showAuthForm} 
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            <Authenticate 
            setAccountInfo={setAccountInfo}
            auth={auth}
            showNotice={showNotice}
            setAuthFormVisible={setShowAuthForm}
            />
          </DialogContent>
      </Dialog>} 
      {/* Mobile Drawer - only add this */}
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { 
            width: 280,
            backgroundColor: theme.palette.background.default
          },
        }}
      >
        <MultiLevelStepper 
          onProcessSelect={handleProcessSelect}
          activeProcessId={currentProcess}
          completedProcesses={completedProcesses}
          isProcessRunning={isAnyProcessRunning}
          isDarkTheme={isDarkTheme}
          onButtonHighlight={handleButtonHighlight}
          sessionId={sessionId}
        />
      </Drawer>
    </div>
  );
}

export default App;
