import React, { useState, useEffect } from 'react';
import { 
  Stepper, 
  Step, 
  StepButton,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Check } from '@mui/icons-material';
import processConfig from '../../config/processIndex.json';
import { ProcessChainManager } from '../../utils/processChain';

const getCustomTheme = (isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: '#FD9800',
      light: 'rgba(253, 152, 0, 0.12)',
    },
    success: {
      main: '#28a745',
    },
    background: {
      paper: isDarkMode ? '#1C1C1E' : '#FFFFFF',
      default: isDarkMode ? '#000000' : '#F5F5F5',
    },
    text: {
      primary: isDarkMode ? '#FFFFFF' : '#1C1C1E',
      secondary: isDarkMode ? '#8E8E93' : '#6C757D',
    },
    divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          padding: '8px 12px',
          maxWidth: 300,
          backgroundColor: isDarkMode ? '#2C2C2E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1C1C1E',
          border: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
        labelContainer: {
          color: 'inherit',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#28a745',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@keyframes pulse': {
          '0%': {
            boxShadow: '0 0 0 0 rgba(253, 152, 0, 0.4)',
          },
          '70%': {
            boxShadow: '0 0 0 10px rgba(253, 152, 0, 0)',
          },
          '100%': {
            boxShadow: '0 0 0 0 rgba(253, 152, 0, 0)',
          },
        },
      },
    },
  },
});

const StepContent = ({ process, isCompleted, isLoading }) => (
  <Typography
    variant="body2"
    sx={{
      color: 'inherit',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: 1
    }}
  >
    {isCompleted && <Check fontSize="small" sx={{ color: 'success.main' }} />}
    {process.displayName}
    {isLoading && (
      <Typography
        variant="body2"
        sx={{
          color: 'primary.main',
          fontSize: '0.75rem',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&::after': {
            content: '""',
            display: 'inline-block',
            width: '1em',
            height: '1em',
            border: '2px solid currentColor',
            borderRadius: '50%',
            borderTopColor: 'transparent',
            animation: 'spin 1s linear infinite'
          }
        }}
      >
      </Typography>
    )}
  </Typography>
);

const MultiLevelStepper = ({ 
  onProcessSelect,
  activeProcessId,
  completedProcesses = {},
  isProcessRunning = false,
  isDarkTheme = false,
  onButtonHighlight,
  sessionId,
  loadingButtons = {}
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [toast, setToast] = useState({ open: false, message: '' });
  const [highlightedStep, setHighlightedStep] = useState(null);
  const [processResponses, setProcessResponses] = useState({});

  const getStepperCategories = () => {
    const chainIds = ProcessChainManager.getChainIds();
    
    return chainIds.map(chainId => {
      const metadata = ProcessChainManager.getChainMetadata(chainId);
      const processes = ProcessChainManager.getChainProcesses(chainId)
        .map(processId => {
          const process = processConfig.processes.find(p => p.id === processId);
          return process ? {
            id: process.id,
            displayName: process.displayName,
            description: process.description,
            component: process.viewerComponent
          } : null;
        })
        .filter(Boolean);

      return {
        chainId,
        title: metadata.title,
        description: metadata.description,
        disabled: metadata.disabled,
        processes
      };
    });
  };

  const categories = getStepperCategories();
  const handleNext = () => {
    const nextStep = Math.min(activeStep + 1, categories.length - 1);
    setActiveStep(nextStep);
    if (categories[nextStep]) {
      onProcessSelect(categories[nextStep].id);
    }
  };

  const handleBack = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    setActiveStep(prevStep);
    if (categories[prevStep]) {
      onProcessSelect(categories[prevStep].id);
    }
  };

  const getProcessReadiness = () => {
    return { ready: true };
  };

  const handleStepClick = (processId) => {
    onProcessSelect(processId);
    setActiveStep(categories.findIndex(p => p.id === processId));
  };

  // Load cache when component mounts
  React.useEffect(() => {
    ProcessChainManager.loadCache();
  }, []);

  useEffect(() => {
    if (activeProcessId) {
      setActiveStep(
        categories.findIndex(p => p.id === activeProcessId)
      );
    }
  }, [activeProcessId]);

  return (
    <ThemeProvider theme={getCustomTheme(isDarkTheme)}>
      <Box sx={{ 
        width: '280px',
        height: '100%',
        borderRight: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        overflowY: 'auto',
        position: { xs: 'relative', sm: 'fixed' },
        left: { xs: 'auto', sm: 0 },
        top: { xs: 'auto', sm: '60px' },
        '& .MuiStepper-root': {
          padding: { xs: '14px 10px', sm: '20px 14px' },
        },
        '& .MuiStepConnector-line': {
          minHeight: '24px',
          borderLeftWidth: 2
        },
        '& .MuiStepLabel-root': {
          padding: '4px 0px'
        },
        '& .MuiStepIcon-root': {
          width: 24,
          height: 24,
          color: 'primary.main',
          '&.Mui-active': {
            color: 'primary.main',
          },
          '&.Mui-completed': {
            color: 'success.main',
          }
        },
        '& .MuiStepLabel-label': {
          color: 'text.primary',
          '&.Mui-active': {
            color: 'primary.main',
            fontWeight: 500
          },
          '&.Mui-completed': {
            color: 'text.primary'
          }
        }
      }}>
        {categories.map((category) => (
          <Box key={category.chainId} sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              sx={{
                px: 2,
                py: 1,
                color: 'text.primary',
                borderBottom: '1px solid',
                borderColor: 'divider',
                opacity: category.disabled ? 0.5 : 1
              }}
            >
              {category.title}
            </Typography>
            <Stepper orientation="vertical" nonLinear>
              {category.processes.map((process) => (
                <Step key={process.id} active={process.id == activeProcessId ? 'active' : ''}>
                  <Tooltip title={process.description || ''} placement="right">
                    <StepButton
                      onClick={() => handleStepClick(process.id)}
                      completed={completedProcesses[process.id]?.toString()}
                      disabled={category.disabled}
                      sx={{
                        'padding': '4px',
                        '&:hover': {
                          bgcolor: 'action.hover'
                        },
                        '& .MuiStepLabel-label': {
                          color: 'text.primary',
                          fontWeight: 500,
                          transition: 'all 0.3s ease',
                          '&.Mui-active': {
                            color: 'primary.main',
                          },
                          '&.Mui-completed': {
                            color: 'text.primary',
                          }
                        }
                      }}
                    >
                      <StepContent 
                        process={process}
                        isCompleted={completedProcesses[process.id]}
                        isLoading={process.id === activeProcessId && isProcessRunning}
                      />
                    </StepButton>
                  </Tooltip>
                </Step>
              ))}
            </Stepper>
          </Box>
        ))}
        
        <Snackbar
          open={toast.open}
          autoHideDuration={4000}
          onClose={() => setToast({ ...toast, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setToast({ ...toast, open: false })} 
            severity="info" 
            sx={{ width: '100%' }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default MultiLevelStepper;