import React from 'react';
import { 
  Box, 
  Card, 
  CardHeader, 
  CardContent, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  Grid,
  useTheme
} from '@mui/material';

const BusinessModelViewer = ({ data, onContinue }) => {
  const theme = useTheme();

  if (!data) {
    return (
      <Box p={3}>
        <Typography variant="body1">No business model data available</Typography>
      </Box>
    );
  }

  const hasValidData = Object.keys(data).some(key => 
    Array.isArray(data[key]) && data[key].length > 0
  );

  if (!hasValidData) {
    return (
      <Box p={3}>
        <Typography variant="body1">
          After entering your idea, feel free to run this process to get a detailed overview of your business model.
        </Typography>
      </Box>
    );
  }

  const sections = [
    { title: 'Key Partners', key: 'keyPartners', description: 'Strategic partners to deliver value proposition' },
    { title: 'Key Activities', key: 'keyActivities', description: 'Core activities to operate the business' },
    { title: 'Key Resources', key: 'keyResources', description: 'Essential assets to create and deliver value' },
    { title: 'Value Propositions', key: 'valuePropositions', description: 'Unique benefits offered to customers' },
    { title: 'Customer Relationships', key: 'customerRelationships', description: 'Types of relationships with customers' },
    { title: 'Customer Segments', key: 'customerSegments', description: 'Target markets served' },
    { title: 'Channels', key: 'channels', description: 'Methods to reach and interact with customers' },
    { title: 'Cost Structure', key: 'costStructure', description: 'Key costs associated with operating the business' },
    { title: 'Revenue Streams', key: 'revenueStreams', description: 'Various ways the company generates revenue', fullWidth: true }
  ];

  const renderSection = (section) => (
    <Grid item xs={12} md={section.fullWidth ? 12 : 6} key={section.key}>
      <Card 
        elevation={0}
        sx={{
          height: '100%',
          border: `1px solid ${theme.palette.divider}`,
          '&:hover': {
            boxShadow: theme.shadows[2],
            transition: 'box-shadow 0.3s ease-in-out'
          },
          backgroundColor: 'var(--card-bg)'
        }}
      >
        <CardHeader
          title={section.title}
          titleTypographyProps={{
            variant: 'h6',
            color: 'var(--color-primary)',
            fontWeight: 600
          }}
          sx={{
            backgroundColor: 'var(--card-bg)',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        />
        <CardContent>
          <List dense disablePadding>
            {Array.isArray(data[section.key]) ? 
              data[section.key].map((item, index) => (
                <ListItem key={index} sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'var(--color-text)',
                          display: 'list-item',
                          listStylePosition: 'inside'
                        }}
                      >
                        {item}
                      </Typography>
                    }
                  />
                </ListItem>
              )) : 
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body2" color="text.secondary">
                      {section.description}
                    </Typography>
                  }
                />
              </ListItem>
            }
          </List>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      {sections.map(renderSection)}
    </Grid>
  );
};

export default BusinessModelViewer;