import React from 'react';
import './glow_11_viewer_prodprice.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import processConfig from '../../config/processIndex.json';

const PricingDashboard = ({ data }) => {
  if (!data) {
    return <div>No pricing data available</div>;
  }

  const { productCostBreakdown, pricingData, customerMetrics } = data;

  return (
    <div className="pricing-dashboard">
      {/* Product Cost Breakdown */}
      <div className="dashboard-card cost-breakdown">
        <h3>Product Cost Breakdown ({productCostBreakdown.period || 'Total'})</h3>
        <div className="card-content">
          <ul>
            {productCostBreakdown?.map((item) => (
              <li key={item.component}>
                <span>{item.component}</span>
                <span>${item.cost}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Pricing Comparison Chart */}
      <div className="dashboard-card pricing-chart">
        <h3>Pricing Comparison</h3>
        <div className="card-content">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={pricingData}>
              <XAxis dataKey="segment" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Bar dataKey="marketPrice" name="Market Price" fill="#8884d8" />
              <Bar dataKey="suggestedPrice" name="Suggested Price" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Customer Metrics Table */}
      <div className="dashboard-card customer-metrics">
        <h3>Customer Metrics</h3>
        <div className="card-content">
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid var(--border-color)' }}>
                <th style={{ textAlign: 'left', padding: '8px' }}>Segment</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>CAC</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>LTV</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(customerMetrics?.cac || {}).map((segment) => (
                <tr 
                  key={segment}
                  style={{ borderBottom: '1px solid var(--border-color-light)' }}
                >
                  <td style={{ padding: '8px' }}>{segment}</td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    ${customerMetrics.cac[segment]}
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    ${customerMetrics.ltv[segment]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingDashboard;